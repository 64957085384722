@import './variables.module.scss';


#submit:hover{opacity: 0.5;}
#closeBtn:hover {
    color: red;
    cursor: pointer;
  }
  
  .inactive{display:none;}
  .errorBorder{ border: 1px solid rgba(214, 9, 9, 0.8); }
  .Border{ border: 1px solid rgba(9, 228, 93, 0.8); }


  .h1{
    color:darkblue;
    font-size: 2rem;
    font-weight: 400;
    transform: rotate(-45deg);
    float:left;
    margin-top: 50px;
    position:absolute;
 
   /* Legacy vendor prefixes that you probably don't need... */
 
   /* Safari */
   -webkit-transform: rotate(-45deg);
 
   /* Firefox */
   -moz-transform: rotate(-45deg);
 
   /* IE */
   -ms-transform: rotate(-45deg);
 
   /* Opera */
   -o-transform: rotate(-45deg);
  }

  @media only screen and (min-width: 992px) {

.modal {
    // display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
     z-index: 2; /* Sit on top */
     left: 23%;
     top: 48%;
     width: 50%; /* Full width */
     height: 42%; /* Full height */
     overflow: hidden; /* Enable scroll if needed */
     background-size: cover;
     background-repeat:  no-repeat;
     background-position: center;
     background-attachment: fixed;
     align-content: center;
     .container {
       width: 100%; 
       height: 100%;
       background: $formColor; /* Fallback color */
       background: rgba(6, 15, 117, 0.8); 
       position: absolute;
       #svg{
         height:25px;
         width:25px;
       }
       #line1{
         stroke:$backColor;
         stroke-width:3;
       }
       #line2{
         stroke:$backColor;
         stroke-width:3;
       }
       /* The Close Button */
       .closeBtn {
         /* Position it in the top right corner outside of the modal */
         position: absolute;
         right: 40px;
         top: 20px;
         font-size: 30px;
         font-weight: bold;
       }
 
     }
   }


   #desc{
    top:60px;
    left:10%;
    position:absolute;
    font-size: 1rem;
    color:$backColor;
    text-align: justify;
    text-justify: inter-word;
   }

   #email{
    height: 30px;
    width: 70%;
    top:140px;
    left:10%;
    position:absolute;
    font-size: 0.9rem;
    box-sizing: border-box;
    background-color: $white;
   }

   #uError{
    height: 20px;
    width: 5px;
    color:red;
    top:140px;
    left:7%;
    position:absolute;
  }

   #submit{
    height: 40px;
    width: 70%;
    top:190px;
    left:10%;
    position:absolute;
    color:$backColor;
    background-color: $linkColor;
    border-radius: 5px;
    z-index: 17;
    &:hover{color:red;}
  }

  #signLabel{
    height: 20px;
    top:250px;
    left:10%;
    position:absolute;
    color:$backColor;
  }


  #signIn{
    height: 20px;
    width: 100px;
    top:250px;
    left:25%;
    position:absolute;
    color:$backColor;
    &:hover{cursor:pointer; color:red}
     
  }
}
@media only screen and ( min-width: 768px) and (max-width: 991px) {
  
.modal {
  // display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
   z-index: 2; /* Sit on top */
   left: 23%;
   top: 44%;
   width: 66%; /* Full width */
   height: 46%; /* Full height */
   overflow: hidden; /* Enable scroll if needed */
   background-size: cover;
   background-repeat:  no-repeat;
   background-position: center;
   background-attachment: fixed;
   align-content: center;
   .container {
     width: 100%; 
     height: 100%;
     background: $formColor; /* Fallback color */
     background: rgba(6, 15, 117, 0.8); 
     position: absolute;
     #svg{
       height:25px;
       width:25px;
     }
     #line1{
       stroke:$backColor;
       stroke-width:3;
     }
     #line2{
       stroke:$backColor;
       stroke-width:3;
     }
     /* The Close Button */
     .closeBtn {
       /* Position it in the top right corner outside of the modal */
       position: absolute;
       right: 40px;
       top: 20px;
       font-size: 30px;
       font-weight: bold;
     }

   }
 }


 #desc{
  top:60px;
  left:10%;
  position:absolute;
  font-size: 1rem;
  color:$backColor;
  text-align: justify;
  text-justify: inter-word;
 }

 #email{
  height: 30px;
  width: 70%;
  top:140px;
  left:10%;
  position:absolute;
  font-size: 0.9rem;
  box-sizing: border-box;
  background-color: $white;
 }

 #uError{
  height: 20px;
  width: 5px;
  color:red;
  top:140px;
  left:7%;
  position:absolute;
}

 #submit{
  height: 40px;
  width: 70%;
  top:190px;
  left:10%;
  position:absolute;
  color:$backColor;
  background-color: $linkColor;
  border-radius: 5px;
  z-index: 17;
  &:hover{color:red;}
}

#signLabel{
  height: 20px;
  top:250px;
  left:10%;
  position:absolute;
  color:$backColor;
}


#signIn{
  height: 20px;
  width: 100px;
  top:250px;
  left:25%;
  position:absolute;
  color:$backColor;
  &:hover{cursor:pointer; color:red}
   
}
}
@media only screen and  (min-width: 411px) and (max-width: 767px)  {
  
.modal {
  // display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
   z-index: 2; /* Sit on top */
  // left: 2%;
   top: 43%;
   width: 100%; /* Full width */
   height: 46%; /* Full height */
   overflow: hidden; /* Enable scroll if needed */
   background-size: cover;
   background-repeat:  no-repeat;
   background-position: center;
   background-attachment: fixed;
   align-content: center;
   .container {
     width: 100%; 
     height: 100%;
     background: $formColor; /* Fallback color */
     background: rgba(6, 15, 117, 0.8); 
     position: absolute;
     #svg{
       height:25px;
       width:25px;
     }
     #line1{
       stroke:$backColor;
       stroke-width:3;
     }
     #line2{
       stroke:$backColor;
       stroke-width:3;
     }
     /* The Close Button */
     .closeBtn {
       /* Position it in the top right corner outside of the modal */
       position: absolute;
       right: 40px;
       top: 20px;
       font-size: 30px;
       font-weight: bold;
     }

   }
 }


 #desc{
  top:60px;
  left:2%;
  position:absolute;
  font-size: 1rem;
  color:$backColor;
  text-align: justify;
  text-justify: inter-word;
 }

 #email{
  height: 30px;
  width: 70%;
  top:140px;
  left:10%;
  position:absolute;
  font-size: 0.9rem;
  box-sizing: border-box;
  background-color: $white;
 }

 #uError{
  height: 20px;
  width: 5px;
  color:red;
  top:140px;
  left:7%;
  position:absolute;
}

 #submit{
  height: 40px;
  width: 70%;
  top:190px;
  left:10%;
  position:absolute;
  color:$backColor;
  background-color: $linkColor;
  border-radius: 5px;
  z-index: 17;
  &:hover{color:red;}
}

#signLabel{
  height: 20px;
  top:250px;
  left:10%;
  position:absolute;
  color:$backColor;
}


#signIn{
  height: 20px;
  width: 100px;
  top:250px;
  left:35%;
  position:absolute;
  color:$backColor;
  &:hover{cursor:pointer; color:red}
   
}
}
@media only screen and  (min-width: 280px) and (max-width: 410px)  {
  
.modal {
  // display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
   z-index: 2; /* Sit on top */
   left: 2%;
   top: 44%;
   width: 96%; /* Full width */
   height: 46%; /* Full height */
   overflow: hidden; /* Enable scroll if needed */
   background-size: cover;
   background-repeat:  no-repeat;
   background-position: center;
   background-attachment: fixed;
   align-content: center;
   .container {
     width: 100%; 
     height: 100%;
     background: $formColor; /* Fallback color */
     background: rgba(6, 15, 117, 0.8); 
     position: absolute;
     #svg{
       height:25px;
       width:25px;
     }
     #line1{
       stroke:$backColor;
       stroke-width:3;
     }
     #line2{
       stroke:$backColor;
       stroke-width:3;
     }
     /* The Close Button */
     .closeBtn {
       /* Position it in the top right corner outside of the modal */
       position: absolute;
       right: 40px;
       top: 20px;
       font-size: 30px;
       font-weight: bold;
     }

   }
 }


 #desc{
  top:60px;
  left:1%;
  position:absolute;
  font-size: 1rem;
  color:$backColor;
  text-align: justify;
  text-justify: inter-word;
 }

 #email{
  height: 30px;
  width: 70%;
  top:140px;
  left:10%;
  position:absolute;
  font-size: 0.9rem;
  box-sizing: border-box;
  background-color: $white;
 }

 #uError{
  height: 20px;
  width: 5px;
  color:red;
  top:140px;
  left:7%;
  position:absolute;
}

 #submit{
  height: 40px;
  width: 70%;
  top:190px;
  left:10%;
  position:absolute;
  color:$backColor;
  background-color: $linkColor;
  border-radius: 5px;
  z-index: 17;
  &:hover{color:red;}
}

#signLabel{
  height: 20px;
  top:250px;
  left:10%;
  position:absolute;
  color:$backColor;
}


#signIn{
  height: 20px;
  width: 100px;
  top:250px;
  left:35%;
  position:absolute;
  color:$backColor;
  &:hover{cursor:pointer; color:red}
   
}
}