
//@import './variables.module.scss';
$buttonColor:#0d6efd;
$fontColor: rgb(83, 6, 134);;
$gradiant:rgb(2,0,36);
$backColor:rgb(247, 247, 242);
$linkColor:rgb(44, 44, 131);
$logo-font-size :24px;
$footerWidth: 100%;
$cardColor:rgb(116, 10, 187);
///$inputColor:rgb(21,107,163);
$labelColor:rgb(116, 136, 151);
$inputColor:#ddd;
$placeHolder:rgb(167, 164, 164);
$logofontsize:36px;
$headerWidth: 100%;
$formColor:rgb(6, 15, 117);
$black:black;
$white:white;



.inactive{display:none;}



@media only screen and (min-width: 992px) {
  
  
  ///1560px;
  .footer{
    margin:0;
    top: 6400px; 
    background-color: $gradiant;
    background-size: 100%;
    background-position: center;
    width: 100vw;
    height: 600px;
    float: center;
    position:absolute;
    scrollbar-width: none;
    display:block;

   
  }

  .policyFooter{
    top: 3730px;
    background-color: $gradiant;
    left:0 ;
    background-size: 100%;
    background-position: center;
    width: 99%;
    height: 500px;
    float: center;
    position:absolute;
    scrollbar-width: none;
    display:block;
    font-family:  sans-serif ;
    font-size: 1rem;
  }
   .aboutUsFooter{
    top: 6150px;
    margin-top:0px;
    background-color: $gradiant;
    left:0 ;
    background-size: 100%;
    background-position: center;
    width: 100vw;
    height: 500px;
    float: center;
    position:absolute;
    scrollbar-width: none;
    display:block;
    font-family:  sans-serif ;
    font-size: 1rem;
  }
  .articleFooter{
   
    background-color: $gradiant;
    background-size: 100%;
    background-position: center;
    width: 100vw;
    height: 500px;
    float: center;
    z-index: 8;
    font-family:  sans-serif ;
    font-size: 1rem;
  }
  
.footer_testServices{
   margin-top:140px;
   margin-left:50px;
   color:$backColor;
   display: flex;
   position:absolute;
   flex-direction: column;
   li{
    list-style-type: none;
    padding:2px;
  }
   font-family:  sans-serif ;
   font-size: 1rem;
   
   
}

.footer_developServices{
  margin-top:140px;
  margin-left:330px;
  color:$backColor;
  display: flex;
  position:absolute;
  flex-direction: column;
  text-decoration: none;
  li{ 
    list-style-type: none;
    padding:2px;
  }
  font-family:  sans-serif ;
  font-size: 1rem;
}

.footer_Services{
  margin-top:140px;
  margin-left:700px;
  font-family:  sans-serif ;
  font-size: 1rem;
  display: flex;
  position:absolute;
  flex-direction: column;
  text-decoration: none;
  Link{color:$backColor; cursor: pointer; padding:2px;}
  #who{color:$backColor;&:hover{color: red; padding:2px;}}
  #what{color:$backColor;&:hover{color: red; padding:2px;}}
  #how{color:$backColor;&:hover{color: red; padding:2px;}}
  #joinUs{color:$backColor;&:hover{color: red; padding:2px;}}
  #proposal{color:$backColor;&:hover{color: red; padding:2px;}}
  #blog{color:$backColor;&:hover{color: red; padding:2px;}}
}

.copyright{
  margin-top:400px;
   margin-left:50px;
   color:$backColor;
   font-family:  sans-serif ;
   font-size: 1rem;
   position:absolute;
   a{cursor: pointer;}
   #policy{color:$backColor;&:hover{color: red;}}
   #sitmap{color:$backColor;&:hover{color: red;}}
}
}

@media only screen and ( min-width: 768px) and (max-width: 991px) {

  .footer{
    top: 6300px;
    background-color: $gradiant;
   // left:11px ;
    //padding:10px;
    background-size: 100%;
    background-position: center;
    width: 100vw;
    height: 500px;
    float: center;
    position:absolute;
    scrollbar-width: none;
    display:block;
   // border-radius: 5px;
   
  }

  .policyFooter{
    top: 3730px;
    background-color: $gradiant;
    left:11px ;
    background-size: 100%;
    background-position: center;
    width: 99%;
    height: 500px;
    float: center;
    position:absolute;
    scrollbar-width: none;
    display:block;
    font-family:  sans-serif ;
    font-size: 1rem;
  }
   .aboutUsFooter{
    top: 6150px;
    margin-top:0px;
    background-color: $gradiant;
    left:11px ;
    background-size: 100%;
    background-position: center;
    width: 99%;
    height: 500px;
    float: center;
    position:absolute;
    scrollbar-width: none;
    display:block;
    font-family:  sans-serif ;
    font-size: 1rem;
   
  }

.footer_testServices{
   margin-top:140px;
   margin-left:50px;
   color:$backColor;
   display: flex;
   position:absolute;
   flex-direction: column;
   li{ 
    list-style-type: none;
    padding:2px;
  }
   font-family:  sans-serif ;
   font-size: 1.2rem;
  
   
}

.footer_developServices{
  margin-top:140px;
  margin-left:300px;
  color:$backColor;
  display: flex;
  position:absolute;
  flex-direction: column;
  text-decoration: none;
  li{ 
    list-style-type: none;
    padding:2px;
  }
  font-family:  sans-serif ;
  font-size: 1rem;
}


.footer_Services{
  margin-top:140px;
  margin-left:600px; 
  display: flex;
  position:absolute;
  flex-direction: column;
  text-decoration: none;
  font-family:  sans-serif ;
  font-size: 1rem;
  Link{color:$backColor; cursor: pointer; padding:2px;}
  #who{color:$backColor;&:hover{color: red; padding:2px;}}
  #what{color:$backColor;&:hover{color: red; padding:2px;}}
  #how{color:$backColor;&:hover{color: red; padding:2px;}}
  #joinUs{color:$backColor;&:hover{color: red; padding:2px;}}
  #proposal{color:$backColor;&:hover{color: red; padding:2px;}}
  #blog{color:$backColor;&:hover{color: red; padding:2px;}}
}
.copyright{
   margin-left:50px;
   color:$backColor;
   bottom: 100px;
   position:absolute;
   font-family:  sans-serif ;
   font-size: 1rem;
   a{cursor: pointer;}
   #policy{color:$backColor;&:hover{color: red;}}
   #sitmap{color:$backColor;&:hover{color: red;}}
}
}

@media only screen and  (min-width: 411px) and (max-width: 767px)  {
  .footer{
    top: 6300px;
    background-color: $gradiant;
    background-size: 100%;
    background-position: center;
    width: 100vw;
    height: 800px;
    float: center;
    position:absolute;
    scrollbar-width: none;
    display:block;
   
  }

  .policyFooter{
    top: 3730px;
    background-color: $gradiant;
    left:11px ;
    background-size: 100%;
    background-position: center;
    width: 99%;
    height: 800px;
    float: center;
    position:absolute;
    scrollbar-width: none;
    display:block;
    font-family:  sans-serif ;
    font-size: 1rem;
  }
   .aboutUsFooter{
    top: 6150px;
    margin-top:0px;
    background-color: $gradiant;
    left:11px ;
    background-size: 100%;
    background-position: center;
    width: 99%;
    height: 800px;
    float: center;
    position:absolute;
    scrollbar-width: none;
    display:block;
    font-family:  sans-serif ;
    font-size: 1rem;
   
  }

.footer_testServices{
   margin-top:140px;
   margin-left:50px;
   color:$backColor;
   display: flex;
   position:absolute;
   flex-direction: column;
   font-family:  sans-serif ;
   font-size: 1rem;
   li{ 
    list-style-type: none;
    padding:2px;
  }
  
   
}

.footer_developServices{
  margin-top:340px;
  margin-left:50px;
  color:$backColor;
  display: flex;
  position:absolute;
  flex-direction: column;
  text-decoration: none;
  font-family:  sans-serif ;
  font-size: 1rem;
  li{ 
    list-style-type: none;
    padding:2px;
  }
}


.footer_Services{
  margin-top:540px;
  margin-left:90px;
  font-family:  sans-serif ;
  font-size: 1rem; 
  display: flex;
  position:absolute;
  flex-direction: column;
  text-decoration: none;
  Link{color:$backColor; cursor: pointer; padding:2px;}
  #who{color:$backColor;&:hover{color: red; padding:2px;}}
  #what{color:$backColor;&:hover{color: red; padding:2px;}}
  #how{color:$backColor;&:hover{color: red; padding:2px;}}
  #joinUs{color:$backColor;&:hover{color: red; padding:2px;}}
  #proposal{color:$backColor;&:hover{color: red; padding:2px;}}
  #blog{color:$backColor;&:hover{color: red; padding:2px;}}
}
.copyright{
   margin-left:50px;
   color:$backColor;
   bottom: 50px;
   position:absolute;
   font-family:  sans-serif ;
   font-size: 1rem;
   a{cursor: pointer;}
   #policy{color:$backColor;&:hover{color: red;}}
   #sitmap{color:$backColor;&:hover{color: red;}}
}
}


@media only screen and  (min-width: 280px) and (max-width: 410px)  {

  .footer{
    top: 6300px;
    background-color: $gradiant;
    background-size: 100%;
    background-position: center;
    width: 100vw;
    height: 800px;
    float: center;
    position:absolute;
    scrollbar-width: none;
    display:block;
  
  }

  .policyFooter{
    top: 3730px;
    background-color: $gradiant;
    background-size: 100%;
    background-position: center;
    width: 99%;
    height: 800px;
    float: center;
    position:absolute;
    scrollbar-width: none;
    display:block;
    font-family:  sans-serif ;
    font-size: 1rem;
  }
   .aboutUsFooter{
    top: 6150px;
    margin-top:0px;
    background-color: $gradiant;
    background-size: 100%;
    background-position: center;
    width: 99%;
    height: 800px;
    float: center;
    position:absolute;
    scrollbar-width: none;
    display:block;
    font-family:  sans-serif ;
    font-size: 1rem;
  }

.footer_testServices{
   margin-top:140px;
   margin-left:20px;
   color:$backColor;
   display: flex;
   position:absolute;
   flex-direction: column;
   font-family:  sans-serif ;
   font-size: 1rem;
   li{ 
    list-style-type: none;
    padding:2px;
  }
    
}

.footer_developServices{
  margin-top:340px;
  margin-left:20px;
  color:$backColor;
  display: flex;
  position:absolute;
  flex-direction: column;
  text-decoration: none;
  font-family:  sans-serif ;
  font-size: 1rem;
  li{ 
    list-style-type: none;
    padding:2px;
  }
}


.footer_Services{
  margin-top:540px;
  margin-left:60px;
  display: flex;
  position:absolute;
  flex-direction: column;
  text-decoration: none;
  font-family:  sans-serif ;
  font-size: 1rem;
  Link{color:$backColor; cursor: pointer; padding:2px;}
  #who{color:$backColor;&:hover{color: red; padding:2px;}}
  #what{color:$backColor;&:hover{color: red; padding:2px;}}
  #how{color:$backColor;&:hover{color: red; padding:2px;}}
  #joinUs{color:$backColor;&:hover{color: red; padding:2px;}}
  #proposal{color:$backColor;&:hover{color: red; padding:2px;}}
  #blog{color:$backColor;&:hover{color: red; padding:2px;}}
}
.copyright{
   margin-left:30px;
   color:$backColor;
   bottom: 50px;
   position:absolute;
   a{cursor: pointer;}
   font-family:  sans-serif ;
   font-size: 1rem;
   #policy{color:$backColor;&:hover{color: red;}}
   #sitmap{color:$backColor;&:hover{color: red;}}
}
      }

     

    
 


