///@import './variables.module.scss';
$buttonColor:#0d6efd;
$fontColor: rgb(83, 6, 134);
$gradiant:rgb(2,0,36);
$backColor:rgb(247, 247, 242);
$linkColor:rgb(44, 44, 131);
$logo-font-size :24px;
$footerWidth: 100%;
$cardColor:rgb(116, 10, 187);
///$inputColor:rgb(21,107,163);
$labelColor:rgb(116, 136, 151);
$inputColor:#ddd;
$placeHolder:rgb(167, 164, 164);
$logofontsize:36px;
$headerWidth: 100%;
$formColor:rgb(6, 15, 117);
$black:black;
$white:white;




#submit:hover{opacity: 0.5;}
#rfp:hover{opacity: 0.5;}
#closeBtn:hover {
    color: red;
    cursor: pointer;
  }
  .inactive{display:none;}
  .errorBorder{ border: 1px solid rgba(214, 9, 9, 0.8); }
  .Border{ border: 1px solid rgba(9, 104, 228, 0.8); }
   .allow{color:rgb(166, 196, 166)}
   .notallow{color:red}



    #customerName::placeholder{  color:$placeHolder;};
    #customerMail::placeholder{  color:$placeHolder;};
    #phone::placeholder{  color:$placeHolder;};
    #customerMessage::placeholder{  color:$placeHolder;};
    #customerName::focus{background-color:#ddd; outline: none;};
    #customerMail::focus{background-color:#ddd ; outline: none;};
    #phone::focus{background-color:#ddd ; outline: none;};
    #customerMessage::focus{background-color:#ddd ; outline: none;};
    .h1{
      color:darkblue;
      font-size: 2rem;
      font-weight: 400;
      transform: rotate(-45deg);
      float:left;
      margin-top: 50px;
      position:absolute;
   
     /* Legacy vendor prefixes that you probably don't need... */
   
     /* Safari */
     -webkit-transform: rotate(-45deg);
   
     /* Firefox */
     -moz-transform: rotate(-45deg);
   
     /* IE */
     -ms-transform: rotate(-45deg);
   
     /* Opera */
     -o-transform: rotate(-45deg);
    }
    
    @media only screen and (min-width: 992px) {

    #contactUs {
     //   border: 3px solid $inputColor;
      //  color:$gradiant;
        background: $formColor; /* Fallback color */
        background: rgba(6, 15, 117, 0.4);  
        width: 80%;
        height: 600px;
        z-index: 5;
        opacity: 1;
        margin-left:10%;
        font-size: 0.9rem;
      }

      .modalContactUs {
        
           position:fixed;
           top:15%;
           width:85%;
           height: 600px;
           z-index: 6;
        //   opacity: 1;
           left:5%;
           overflow: hidden; /* Enable scroll if needed */
           background-size: cover;
           background-repeat:  no-repeat;
           background-position: center;
           background-attachment: fixed;
           font-family:  sans-serif ;
      }
      .fj  .fp {
        margin-top:-6150px;
        background: $buttonColor;
        position:absolute;
     
        width:85%;
        height: 600px;
        z-index: 7;
     //   opacity: 1;
        margin-left:5%;
       overflow: hidden; /* Enable scroll if needed */
        background-size: cover;
        background-repeat:  no-repeat;
        background-position: center;
        background-attachment: fixed;
   }
           .container {
            width: 100%; 
            height: 100%;
            background: $formColor; /* Fallback color */
            background: rgba(6, 15, 117, 0.8); 
            position: absolute;
            font-size: 0.9rem;
            #svg{
              height:25px;
              width:25px;
            }
            #line1{
              stroke:$backColor;
              stroke-width:3;
            }
            #line2{
              stroke:$backColor;
              stroke-width:3;
            }
            /* The Close Button */
            .closeBtn {
              /* Position it in the top right corner outside of the modal */
              position: absolute;
              right: 40px;
              top: 20px;
              font-size: 30px;
              font-weight: bold;
            }
      
          }
        

      #title{
        top: 0;
        left:13%;
        font-size: 1.8rem;
        color:white;
        display: block;
        position:absolute;
        z-index: 3;
        font-family:  sans-serif ;
       
       }
       #note{
        top: 50px;
        left:13%;
        color:white;
        display: block;
        position:absolute;
        z-index: 3;
        font-family:  sans-serif ;
        font-size: 1.2rem;
     
       }
      #customerName{
        height: 30px;
        top: 90px;
        left:13%;
        width: 74%;
        padding: 12px 20px;
        margin: 8px 0;
        display: block;
        position:absolute;
    //    border: 1px solid $labelColor;
        box-sizing: border-box;
        z-index: 3;
        background-color: $backColor;
        border-radius: 3px;
        font-size: 0.9rem;
        font-family:  sans-serif ;
        
    }
    #nError{
      height: 20px;
      width: 5px;
      color:red;
      top:90px;
      left:12%;
      position:absolute;
    }
    
            #customerMail{
                height: 30px;
                top: 120px;
                left:13%;
                width: 37%;
                padding: 12px 20px;
                margin: 8px 0;
                display: block;
                position:absolute;
        //        border: 1px solid $labelColor;
                box-sizing: border-box;
                z-index: 3;
                border-radius: 3px;
                background-color: $backColor;
                color:$gradiant;
                font-size: 0.9rem;
                font-family:  sans-serif ;
              }

              #mError{
                height: 20px;
                width: 5px;
                color:red;
                top:120px;
                left:12%;
                position:absolute;
              }

            #phone{
                height: 30px;
                top: 120px;
                left:50%;
                width: 37%;
                padding: 12px 20px;
                margin: 8px 0;
                display: block;
                position:absolute;
        //        border: 1px solid $labelColor;
                box-sizing: border-box;
                z-index: 3;
                border-radius: 3px;
                background-color: $backColor;
                font-size: 0.9rem;
                font-family:  sans-serif ;
            }
            #pError{
              height: 20px;
              width: 5px;
              color:red;
              top:120px;
              left:88%;
              position:absolute;
            }
           #customerMessage{
            height: 200px;
            top: 150px;
            left:13%;
            width: 74%;
            padding: 12px 20px;
            margin: 8px 0;
            display: block;
            position:absolute;
   //         border: 1px solid $labelColor;
            box-sizing: border-box;
            z-index: 3;
            border-radius: 3px;
            background-color: $backColor;
            font-size: 0.9rem;
            font-family:  sans-serif ;
           }
         #rf{
            height: 30px;
            top: 374.1px;
            left:33%;        
            padding: 1px 20px;
            margin: 8px 0;
            display: block;
            position:absolute;
          //  border: 1px solid $gradiant;
            box-sizing: border-box;          
            color:$backColor;
            background-color: $linkColor;
            border-radius: 3px;
            font-size: 1rem;
            font-family:  sans-serif ;

           }
           #rfp{
            height: 30px;
            top: 373px;
            left:13.5%;
            width: 20%;
            margin: 8px 0;
            display: block;
            position:absolute;
            color:$backColor;
            padding: 0px 2px;
          //  font-weight: bold;
            z-index: 3;
          ///  border: 1px solid $gradiant;
            border-radius: 3px;
            color:$backColor;
            background-color: $linkColor;
            font-size: 1rem;
              font-family:  sans-serif ;
           }

           #policy{
            height: 4%;
            top: 410px;
            left:13%;
            width: 3%;
            margin: 8px 0;
            display: block;
            position:absolute;
            color:$backColor;
            font-size: 1rem;
            font-weight: bold;
            z-index: 3;
            color:$backColor;
            background-color: $linkColor;
            font-size: 1rem;
            font-family:  sans-serif ;
           }

           #confirm{
            height: 25px;
            top: 410px;
            left:14%;
         
            padding-left:  25px;
            margin: 8px 0;
            display: block;
            position:absolute;
        ///    border: 1px solid $gradiant;
            box-sizing: border-box;
            font-size: 1rem;
            color:$backColor;
            background-color: $linkColor;
            border-radius: 3px;
            font-size: 1rem;
              font-family:  sans-serif ;
      
           }
          #dError{
           color:rgba(214, 9, 9, 0.8);
           font-size: 1rem;
           top:440px;
           left:17%;
           height: 20px;
           position:absolute;
          }
          .rnc{
            display: flex;
            flex-direction: column;
            width: 100%;
            max-width: 220px; //21%;
            background-color: #eee;
            border-radius: 6px;
            padding: 8px;
            box-sizing: border-box;
            top:360px;
            left:63%;
            position:absolute;
            
          }
          .rnc-row {
            display: flex;
            align-items: stretch;
            margin: 0 0 8px 0;
          }
          
          .rnc-column {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin: 0 0 0 8px;
          }
          
          .rnc-canvas {
            box-sizing: border-box;
            background-color: #fff;
            border-radius: 4px;
            font-size: 1rem;
          }
          
          .rnc-input {
            border: none;
            padding: 5px 3px;
            font-size: .5rem;
            font-family: inherit;
          }
          .rnc-button {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 12px;
            background: #fff;
            color: inherit;
            border: none;
            padding: 0;
            width: 25px;
            height: 20px;
            box-sizing: border-box;
            border-radius: 4px;
          }
          
           svg {
            width: 1em;
            height: 1em;
            fill: currentColor;
          }
          
          .rnc-input {
            border: none;
            padding: 10px 6px;
            font-size: inherit;
            font-family: inherit;
          }
      #submit {
        color:$backColor;
        background-color: $linkColor;
        border: 1px solid $gradiant;
        position:absolute;
        font-size: 1.2rem;
        padding: 14px 20px;
        margin: 8px 0;
        border: none;
        cursor: pointer;
        width: 74%;
        top:480px;
        left:13%;
        z-index: 3;
        font-family:  sans-serif ;
        border-radius: 3px;
       
      }
     
      
      /* Add a hover effect for buttons */
      button:hover {
        opacity: 0.8;
      }

    
    }

    @media only screen and ( min-width: 768px) and (max-width: 991px) {

      #contactUs {
        //   border: 3px solid $inputColor;
         //  color:$gradiant;
           background: $formColor; /* Fallback color */
           background: rgba(6, 15, 117, 0.4); 
          
           width: 80%;
           height: 600px;
           z-index: 5;
           opacity: 1;
           margin-left:10%;
           font-size: 0.9rem;
           font-family:  sans-serif ;
         }
   
         .modalContactUs {
           
              position:fixed;
              top:15%;
              width:85%;
              height: 600px;
              z-index: 6;
           //   opacity: 1;
              left:5%;
              overflow: hidden; /* Enable scroll if needed */
              background-size: cover;
              background-repeat:  no-repeat;
              background-position: center;
              background-attachment: fixed;
              font-family:  sans-serif ;
         }
         .fj  .fp {
           margin-top:-6150px;
           background: $buttonColor;
           position:absolute;
        
           width:85%;
           height: 600px;
           z-index: 7;
        //   opacity: 1;
           margin-left:5%;
          overflow: hidden; /* Enable scroll if needed */
           background-size: cover;
           background-repeat:  no-repeat;
           background-position: center;
           background-attachment: fixed;
      }
              .container {
               width: 100%; 
               height: 100%;
               background: $formColor; /* Fallback color */
               background: rgba(6, 15, 117, 0.8); 
               position: absolute;
               font-size: 0.9rem;
               #svg{
                 height:25px;
                 width:25px;
               }
               #line1{
                 stroke:$backColor;
                 stroke-width:3;
               }
               #line2{
                 stroke:$backColor;
                 stroke-width:3;
               }
               /* The Close Button */
               .closeBtn {
                 /* Position it in the top right corner outside of the modal */
                 position: absolute;
                 right: 40px;
                 top: 20px;
                 font-size: 30px;
                 font-weight: bold;
               }
         
             }
           
   
         #title{
           margin-top: 0px;
           left:13%;
           font-size: 1.8rem;
           color:white;
           display: block;
           position:absolute;
           z-index: 3;
           font-family:  sans-serif ;
          }
          #note{
           margin-top: 50px;
           left:13%;
           font-size: 1.2rem;
           color:white;
           display: block;
           position:absolute;
           z-index: 3;
           font-family: sans-serif ;
          }
         #customerName{
           height: 30px;
           top: 90px;
           left:13%;
           width: 74%;
           padding: 12px 20px;
           margin: 8px 0;
           display: block;
           position:absolute;
       //    border: 1px solid $labelColor;
           box-sizing: border-box;
           z-index: 3;
           background-color: $backColor;
           border-radius: 3px;
           font-size: 0.9rem;
           font-family:  sans-serif ;
       }
       #nError{
         height: 20px;
         width: 5px;
         color:red;
         top:90px;
         left:12%;
         position:absolute;
       }
       
               #customerMail{
                   height: 30px;
                   top: 120px;
                   left:13%;
                   width: 37%;
                   padding: 12px 20px;
                   margin: 8px 0;
                   display: block;
                   position:absolute;
           //        border: 1px solid $labelColor;
                   box-sizing: border-box;
                   z-index: 3;
                   border-radius: 3px;
                   background-color: $backColor;
                   color:$gradiant;
                   font-size: 0.9rem;
                   font-family:  sans-serif ;
                 }
   
                 #mError{
                   height: 20px;
                   width: 5px;
                   color:red;
                   top:120px;
                   left:12%;
                   position:absolute;
                 }
   
               #phone{
                   height: 30px;
                   top: 120px;
                   left:50%;
                   width: 37%;
                   padding: 12px 20px;
                   margin: 8px 0;
                   display: block;
                   position:absolute;
           //        border: 1px solid $labelColor;
                   box-sizing: border-box;
                   z-index: 3;
                   border-radius: 3px;
                   background-color: $backColor;
                   font-size: 0.9rem;
                   font-family:  sans-serif ;
               }
               #pError{
                 height: 20px;
                 width: 5px;
                 color:red;
                 top:120px;
                 left:88%;
                 position:absolute;
               }
              #customerMessage{
               height: 200px;
               top: 150px;
               left:13%;
               width: 74%;
               padding: 12px 20px;
               margin: 8px 0;
               display: block;
               position:absolute;
      //         border: 1px solid $labelColor;
               box-sizing: border-box;
               z-index: 3;
               border-radius: 3px;
               background-color: $backColor;
               font-size: 0.9rem;
               font-family:  sans-serif ;
              }
            #rf{
               height: 30px;
               top: 374.1px;
               left:32%;        
               padding: 1px 20px;
               margin: 8px 0;
               display: block;
               position:absolute;
               border: 1px solid $gradiant;
               box-sizing: border-box;          
               color:$backColor;
               background-color: $linkColor;
               border-radius: 3px;
               font-size: 1rem;
               font-family:  sans-serif ;
              }
              #rfp{
               height: 30px;
               top: 373px;
               left:12.5%;
               width: 20%;
               margin: 8px 0;
               display: block;
               position:absolute;
               color:$backColor;
               padding: 0px 2px;
             //  font-weight: bold;
               z-index: 3;
             //  border: 1px solid $gradiant;
               border-radius: 3px;
               color:$backColor;
               background-color: $linkColor;
               font-size: 1rem;
               font-family:  sans-serif ;
              }
   
              #policy{
               height: 4%;
               top: 410px;
               left:12.5%;
               width: 3%;
               margin: 8px 0;
               display: block;
               position:absolute;
               color:$backColor;
               font-size: 1rem;
               font-weight: bold;
               z-index: 3;
               color:$backColor;
               background-color: $linkColor;
               font-size: 1rem;
               font-family:  sans-serif ;
              }
   
              #confirm{
               height: 25px;
               top: 410px;
               left:13%;
            
               padding-left:  25px;
               margin: 8px 0;
               display: block;
               position:absolute;
              /// border: 1px solid $gradiant;
               box-sizing: border-box;
               font-size: 1rem;
               color:$backColor;
               background-color: $linkColor;
               border-radius: 3px;
               font-size: 1rem;
               font-family:  sans-serif ;
              }
             #dError{
              color:rgba(214, 9, 9, 0.8);
              font-size: 1rem;
              top:440px;
              left:17%;
              height: 20px;
              position:absolute;
             }
             .rnc{
               display: flex;
               flex-direction: column;
               width: 100%;
               max-width: 220px; //21%;
               background-color: #eee;
               border-radius: 6px;
               padding: 8px;
               box-sizing: border-box;
               top:360px;
               left:63%;
               position:absolute;
               
             }
             .rnc-row {
               display: flex;
               align-items: stretch;
               margin: 0 0 8px 0;
             }
             
             .rnc-column {
               display: flex;
               flex-direction: column;
               justify-content: space-between;
               margin: 0 0 0 8px;
             }
             
             .rnc-canvas {
               box-sizing: border-box;
               background-color: #fff;
               border-radius: 4px;
               font-size: 1rem;
             }
             
             .rnc-input {
               border: none;
               padding: 5px 3px;
               font-size: .5rem;
               font-family: inherit;
             }
             .rnc-button {
               display: flex;
               justify-content: center;
               align-items: center;
               font-size: 12px;
               background: #fff;
               color: inherit;
               border: none;
               padding: 0;
               width: 25px;
               height: 20px;
               box-sizing: border-box;
               border-radius: 4px;
             }
             
              svg {
               width: 1em;
               height: 1em;
               fill: currentColor;
             }
             
             .rnc-input {
               border: none;
               padding: 10px 6px;
               font-size: inherit;
               font-family: inherit;
             }
         #submit {
           color:$backColor;
           background-color: $linkColor;
           border: 1px solid $gradiant;
           position:absolute;
           font-size: 1.2rem;
           padding: 14px 20px;
           margin: 8px 0;
           border: none;
           cursor: pointer;
           width: 74%;
           top:480px;
           left:13%;
           z-index: 3;
           font-family:  sans-serif ;
           border-radius: 3px;
          
         }
        
         
         /* Add a hover effect for buttons */
         button:hover {
           opacity: 0.8;
         }
   
    }

    @media only screen and  (min-width: 411px) and (max-width: 767px)  {

      #contactUs {
        
           background: $formColor; /* Fallback color */
           background: rgba(6, 15, 117, 0.4); 
           width: 100%;
           height: 600px;
           z-index: 5;
           opacity: 1;
        //   margin-left:10%;
           font-size: 0.9rem;
         }
   
         .modalContactUs {
              position:fixed;
              top:15%;
              width:100%;
              height: 600px;
              z-index: 6;
           //   opacity: 1;
            //  left:5%;
              overflow: hidden; /* Enable scroll if needed */
              background-size: cover;
              background-repeat:  no-repeat;
              background-position: center;
              background-attachment: fixed;
         }
         .fj  .fp {
           margin-top:-6150px;
           background: $buttonColor;
           position:absolute;
        
           width:100%;
           height: 600px;
           z-index: 7;
        //   opacity: 1;
        //   margin-left:5%;
          overflow: hidden; /* Enable scroll if needed */
           background-size: cover;
           background-repeat:  no-repeat;
           background-position: center;
           background-attachment: fixed;
      }
              .container {
               width: 100%; 
               height: 100%;
               background: $formColor; /* Fallback color */
               background: rgba(6, 15, 117, 0.8); 
               position: absolute;
               font-size: 0.9rem;
               #svg{
                 height:25px;
                 width:25px;
               }
               #line1{
                 stroke:$backColor;
                 stroke-width:3;
               }
               #line2{
                 stroke:$backColor;
                 stroke-width:3;
               }
               /* The Close Button */
               .closeBtn {
                 /* Position it in the top right corner outside of the modal */
                 position: absolute;
                 right: 40px;
                 top: 20px;
                 font-size: 30px;
                 font-weight: bold;
               }
         
             }
           
   
         #title{
           top: 0px;
           left:3%;
           font-size: 1.8rem;
           color:white;
           display: block;
           position:absolute;
           z-index: 3;
           font-family:  sans-serif ;
          }
          #note{
           top: 40px;
           left:3%;
           font-size: 1rem;
           color:white;
           display: block;
           position:absolute;
           z-index: 3;
           font-family:  sans-serif ;
          }
         #customerName{
           height: 30px;
           top: 90px;
           left:3%;
           width: 94%;
           padding: 12px 20px;
           margin: 8px 0;
           display: block;
           position:absolute;
       //    border: 1px solid $labelColor;
           box-sizing: border-box;
           z-index: 3;
           background-color: $backColor;
           border-radius: 3px;
           font-size: 0.9rem;
           font-family:  sans-serif ;
       }
       #nError{
         height: 20px;
         width: 5px;
         color:red;
         top:90px;
         left:2%;
         position:absolute;
       }
       
               #customerMail{
                   height: 30px;
                   top: 120px;
                   left:3%;
                   width: 47%;
                   padding: 12px 20px;
                   margin: 8px 0;
                   display: block;
                   position:absolute;
           //        border: 1px solid $labelColor;
                   box-sizing: border-box;
                   z-index: 3;
                   border-radius: 3px;
                   background-color: $backColor;
                   color:$gradiant;
                   font-size: 0.9rem;
                   font-family:  sans-serif ;
                 }
   
                 #mError{
                   height: 20px;
                   width: 5px;
                   color:red;
                   top:120px;
                   left:2%;
                   position:absolute;
                 }
   
               #phone{
                   height: 30px;
                   top: 120px;
                   left:50%;
                   width: 47%;
                   padding: 12px 20px;
                   margin: 8px 0;
                   display: block;
                   position:absolute;
           //        border: 1px solid $labelColor;
                   box-sizing: border-box;
                   z-index: 3;
                   border-radius: 3px;
                   background-color: $backColor;
                   font-size: 0.9rem;
                   font-family:  sans-serif ;
               }
               #pError{
                 height: 20px;
                 width: 5px;
                 color:red;
                 top:120px;
                 left:88%;
                 position:absolute;
               }
              #customerMessage{
               height: 200px;
               top: 150px;
               left:3%;
               width: 94%;
               padding: 12px 20px;
               margin: 8px 0;
               display: block;
               position:absolute;
      //         border: 1px solid $labelColor;
               box-sizing: border-box;
               z-index: 3;
               border-radius: 3px;
               background-color: $backColor;
               font-size: 0.9rem;
               font-family:  sans-serif ;
              }
            #rf{
               height: 27px;
               top: 374.1px;
               left:32%;        
               padding: 1px 20px;
               margin: 8px 0;
               display: block;
               position:absolute;
               border: 1px solid $gradiant;
               box-sizing: border-box;          
               color:$backColor;
               background-color: $linkColor;
               border-radius: 3px;
               font-size: 0.9rem;
               font-family:  sans-serif ;
              }
              #rfp{
               height: 27px;
               top: 373px;
               left:3%;
               width: 24%;
               margin: 8px 0;
               display: block;
               position:absolute;
               color:$backColor;
               padding: 0px 2px;
             //  font-weight: bold;
               z-index: 3;
               border: 1px solid $gradiant;
               border-radius: 3px;
               color:$backColor;
               background-color: $linkColor;
               font-size: 0.9rem;
               font-family:  sans-serif ;
              }
   
              #policy{
               height: 4%;
               top: 410px;
               left:3%;
               width: 4%;
               margin: 8px 0;
               display: block;
               position:absolute;
               color:$backColor;
               font-size: 1rem;
               font-weight: bold;
               z-index: 3;
               color:$backColor;
               background-color: $linkColor;
               font-size: 0.9rem;
               font-family:  sans-serif ;
              }
   
              #confirm{
               height: 25px;
               top: 410px;
               left:3%;
            
               padding-left:  25px;
               margin: 8px 0;
               display: block;
               position:absolute;
               border: 1px solid $gradiant;
               box-sizing: border-box;
               font-size: 1rem;
               color:$backColor;
               background-color: $linkColor;
               border-radius: 3px;
               font-size: 0.9rem;
               font-family:  sans-serif ;
              }
             #dError{
              color:rgba(214, 9, 9, 0.8);
              font-size: 1rem;
              top:450px;
              left:3%;
              height: 20px;
              position:absolute;
             }
             .rnc{
               display: flex;
               flex-direction: column;
               width: 100%;
               max-width: 220px; //21%;
               background-color: #eee;
               border-radius: 6px;
               padding: 8px;
               box-sizing: border-box;
               top:360px;
               left:45%;
               position:absolute;
               
             }
             .rnc-row {
               display: flex;
               align-items: stretch;
               margin: 0 0 8px 0;
             }
             
             .rnc-column {
               display: flex;
               flex-direction: column;
               justify-content: space-between;
               margin: 0 0 0 8px;
             }
             
             .rnc-canvas {
               box-sizing: border-box;
               background-color: #fff;
               border-radius: 4px;
               font-size: 1rem;
             }
             
             .rnc-input {
               border: none;
               padding: 5px 3px;
               font-size: .5rem;
               font-family: inherit;
             }
             .rnc-button {
               display: flex;
               justify-content: center;
               align-items: center;
               font-size: 12px;
               background: #fff;
               color: inherit;
               border: none;
               padding: 0;
               width: 25px;
               height: 20px;
               box-sizing: border-box;
               border-radius: 4px;
             }
             
              svg {
               width: 1em;
               height: 1em;
               fill: currentColor;
             }
             
             .rnc-input {
               border: none;
               padding: 10px 6px;
               font-size: inherit;
               font-family: inherit;
             }
         #submit {
           color:$backColor;
           background-color: $linkColor;
           border: 1px solid $gradiant;
           position:absolute;
           font-size: 0.9rem;
           padding: 14px 20px;
           margin: 8px 0;
           border: none;
           cursor: pointer;
           width: 94%;
           top:480px;
           left:3%;
           z-index: 3;
           border-radius: 3px;
           font-family:  sans-serif ;
         }
        
         
         /* Add a hover effect for buttons */
         button:hover {
           opacity: 0.8;
         }
   
    }
    @media only screen and  (min-width: 280px) and (max-width: 410px)  {

      #contactUs {
        //   border: 3px solid $inputColor;
         //  color:$gradiant;
           background: $formColor; /* Fallback color */
           background: rgba(6, 15, 117, 0.4); 
           width: 100%;
           height: 600px;
           z-index: 5;
           opacity: 1;
         //  margin-left:10%;
           font-size: 0.9rem;
           font-family:  sans-serif ;
         }
   
         .modalContactUs { 
              position:fixed;
              top:15%;
              width:100%;
              height: 600px;
              z-index: 6;
           //   opacity: 1;
            ///  left:5%;
              overflow: hidden; /* Enable scroll if needed */
              background-size: cover;
              background-repeat:  no-repeat;
              background-position: center;
              background-attachment: fixed;
              font-family:  sans-serif ;
         }
         .fj  .fp {
           margin-top:-6150px;
           background: $buttonColor;
           position:absolute;
        
           width:100%;
           height: 600px;
           z-index: 7;
        //   opacity: 1;
           margin-left:5%;
          overflow: hidden; /* Enable scroll if needed */
           background-size: cover;
           background-repeat:  no-repeat;
           background-position: center;
           background-attachment: fixed;
      }
              .container {
               width: 100%; 
               height: 100%;
               background: $formColor; /* Fallback color */
               background: rgba(6, 15, 117, 0.8); 
               position: absolute;
               font-size: 0.9rem;
               #svg{
                 height:25px;
                 width:25px;
               }
               #line1{
                 stroke:$backColor;
                 stroke-width:3;
               }
               #line2{
                 stroke:$backColor;
                 stroke-width:3;
               }
               /* The Close Button */
               .closeBtn {
                 /* Position it in the top right corner outside of the modal */
                 position: absolute;
                 right: 40px;
                 top: 20px;
                 font-size: 30px;
                 font-weight: bold;
               }
         
             }
           
   
         #title{
           top: 0px;
           left:3%;
           font-size: 1.8rem;
           color:white;
           display: block;
           position:absolute;
           z-index: 3;
           font-family:  sans-serif ;
          }
          #note{
           top: 40px;
           left:3%;
           font-size: 1rem;
           color:white;
           display: block;
           position:absolute;
           z-index: 3;
           font-family:  sans-serif ;
          }
         #customerName{
           height: 30px;
           top: 90px;
           left:3%;
           width: 94%;
           padding: 12px 20px;
           margin: 8px 0;
           display: block;
           position:absolute;
       //    border: 1px solid $labelColor;
           box-sizing: border-box;
           z-index: 3;
           background-color: $backColor;
           border-radius: 3px;
           font-size: 0.9rem;
           font-family:  sans-serif ;
       }
       #nError{
         height: 20px;
         width: 5px;
         color:red;
         top:90px;
         left:2%;
         position:absolute;
       }
       
               #customerMail{
                   height: 30px;
                   top: 120px;
                   left:3%;
                   width: 47%;
                   padding: 12px 20px;
                   margin: 8px 0;
                   display: block;
                   position:absolute;
           //        border: 1px solid $labelColor;
                   box-sizing: border-box;
                   z-index: 3;
                   border-radius: 3px;
                   background-color: $backColor;
                   color:$gradiant;
                   font-size: 0.9rem;
                     font-family:  sans-serif ;
                 }
   
                 #mError{
                   height: 20px;
                   width: 5px;
                   color:red;
                   top:120px;
                   left:2%;
                   position:absolute;
                 }
   
               #phone{
                   height: 30px;
                   top: 120px;
                   left:50%;
                   width: 47%;
                   padding: 12px 20px;
                   margin: 8px 0;
                   display: block;
                   position:absolute;
           //        border: 1px solid $labelColor;
                   box-sizing: border-box;
                   z-index: 3;
                   border-radius: 3px;
                   background-color: $backColor;
                   font-size: 0.9rem;
                   font-family:  sans-serif ;
               }
               #pError{
                 height: 20px;
                 width: 5px;
                 color:red;
                 top:120px;
                 left:88%;
                 position:absolute;
               }
              #customerMessage{
               height: 200px;
               top: 150px;
               left:3%;
               width: 94%;
               padding: 12px 20px;
               margin: 8px 0;
               display: block;
               position:absolute;
      //         border: 1px solid $labelColor;
               box-sizing: border-box;
               z-index: 3;
               border-radius: 3px;
               background-color: $backColor;
               font-size: 0.9rem;
                 font-family:  sans-serif ;
              }
            #rf{
               height: 27px;
               top: 374.1px;
               left:3%;        
               padding: 1px 20px;
               margin: 8px 0;
               display: block;
               position:absolute;
               border: 1px solid $gradiant;
               box-sizing: border-box;          
               color:$backColor;
               background-color: $linkColor;
               border-radius: 3px;
               font-size: 0.9rem;
              }
              #rfp{
               height: 27px;
               top: 373px;
               left:3%;
               width: 30%;
               margin: 8px 0;
               display: block;
               position:absolute;
               color:$backColor;
               padding: 0px 2px;
             //  font-weight: bold;
               z-index: 3;
               border: 1px solid $gradiant;
               border-radius: 3px;
               color:$backColor;
               background-color: $linkColor;
               font-size: 0.9rem;
              }
   
              #policy{
               height: 4%;
               top: 410px;
               left:4%;
               width: 4%;
               margin: 8px 0;
               display: block;
               position:absolute;
               color:$backColor;
               font-size: 1rem;
               font-weight: bold;
               z-index: 3;
               color:$backColor;
               background-color: $linkColor;
               font-size: 0.9rem;
               font-family:  sans-serif ;
              }
   
              #confirm{
               height: 25px;
               top: 410px;
               left:3%;
               padding-left:  25px;
               margin: 8px 0;
               display: block;
               position:absolute;
               border: 1px solid $gradiant;
               box-sizing: border-box;
               font-size: 1rem;
               color:$backColor;
               background-color: $linkColor;
               border-radius: 3px;
               font-size: 0.9rem;
               font-family:  sans-serif ;
              }
             #dError{
              color:rgba(214, 9, 9, 0.8);
              font-size: 1rem;
              top:450px;
              left:3%;
              height: 20px;
              position:absolute;
             }
             .rnc{
               display: flex;
               flex-direction: column;
               width: 100%;
               max-width: 200px; //21%;
               background-color: #eee;
               border-radius: 6px;
               padding: 8px;
               box-sizing: border-box;
               top:360px;
               left:45%;
               position:absolute;
               
             }
             .rnc-row {
               display: flex;
               align-items: stretch;
               margin: 0 0 8px 0;
             }
             
             .rnc-column {
               display: flex;
               flex-direction: column;
               justify-content: space-between;
               margin: 0 0 0 8px;
             }
             
             .rnc-canvas {
               box-sizing: border-box;
               background-color: #fff;
               border-radius: 4px;
               font-size: 1rem;
             }
             
             .rnc-input {
               border: none;
               padding: 5px 3px;
               font-size: .5rem;
               font-family: inherit;
             }
             .rnc-button {
               display: flex;
               justify-content: center;
               align-items: center;
               font-size: 12px;
               background: #fff;
               color: inherit;
               border: none;
               padding: 0;
               width: 25px;
               height: 20px;
               box-sizing: border-box;
               border-radius: 4px;
             }
             
              svg {
               width: 1em;
               height: 1em;
               fill: currentColor;
             }
             
             .rnc-input {
               border: none;
               padding: 10px 6px;
               font-size: inherit;
               font-family: inherit;
             }
         #submit {
           color:$backColor;
           background-color: $linkColor;
           border: 1px solid $gradiant;
           position:absolute;
           font-size: 0.9rem;
           padding: 14px 20px;
           margin: 8px 0;
           border: none;
           cursor: pointer;
           width: 94%;
           top:480px;
           left:3%;
           z-index: 3;
         font-family:  sans-serif ;
           border-radius: 3px;
          
         }
        
         
         /* Add a hover effect for buttons */
         button:hover {
           opacity: 0.8;
         }
   
    }
