@import './variables.module.scss';
 
.inActive{display:none;}


    .acticle{
    margin: 0 auto;
    width:100vw;
    display:grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: auto;
    justify-items: stretch;
    align-items: stretch;
    place-items: center;
    justify-content: center;
    position:relative;
    padding:0px;
    background-color:$backColor;
    float:center;
    align-items: center;
    overflow:  hidden;
    scrollbar-width: none;
     margin-bottom: 0;
        
    }
    
     
    .img{
        width: 100vw;
        height: auto;
        align-content: center;
        position:absolute;
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
        box-sizing: border-box;
      //  background-size: cover;
        background-attachment:scroll;
        background-repeat:  no-repeat;
        background-position: center;
        background-color: ($gradiant,09);
        margin-bottom: 50px;
        display:grid;
        grid-template-columns: subgrid;
        grid-column: 1;
       
      h1{
        margin-left: 30%;
        margin-top: 100px;
        background: -webkit-linear-gradient(yellow, $gradiant);
        background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 34px;
      
      
      }
      h3{
        margin: 30%;
        margin-top: 25px;
        margin-bottom: 100px;
        background: -webkit-linear-gradient(yellow, $gradiant);
        background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 34x;
       
        
      }
      h2{
        margin: 30%;
        margin-top: 25px;
        margin-bottom: 100px;
        background: -webkit-linear-gradient(yellow, $gradiant);
        background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 34x;
        
             }
       
    
      }
      
      .article1{
        width: 80%;
        margin-right: 10%;
        margin-left: 10%;
        position:absolute;
        margin-top:350px;
        display: inline-block;
        background-position: center;
        display:grid;
        grid-template-columns: subgrid;
        grid-column: 1;
        img{
          display: inline-block;
       
        }
        h3{
            background: -webkit-linear-gradient($cardColor, $gradiant);
            background-clip: text;
            -webkit-text-fill-color: transparent;
            font-weight: 200;
            margin-bottom: 20px;
            font-size: 2rem;
       
          }
        p  {
            background: -webkit-linear-gradient($fontColor, $gradiant);
            background-clip: text;
            -webkit-text-fill-color: transparent;
            justify-content: center;   
            margin-bottom: 30px;
            font-size: 1.3rem;
            display: inline-block;
           
        }

        .closerP{
          background: -webkit-linear-gradient($fontColor, $gradiant);
          background-clip: text;
          -webkit-text-fill-color: transparent;
          justify-content: center;   
          margin: 5px;
          font-size: 1.3rem;
      }
         a {
          
          justify-content: center;
          margin-top:0px;
          margin-bottom: 0px;
          font-size: 1.3rem;
          color:$gradiant;
          &:hover{color:$buttonColor}
      }
     }
     .section1{
      width: 80%;
      margin-right: 10%;
      margin-left: 10%;
      position:absolute;
      margin-top:1700px;
      background-repeat:  no-repeat;
      background-position: center;
      display:grid;
      grid-template-columns: subgrid;
      grid-column: 1;
      img{
            margin-bottom: 10px;
            font-size: 2rem; 
      }
      h3{
            background: -webkit-linear-gradient($cardColor, $buttonColor);
            background-clip: text;
            -webkit-text-fill-color: transparent;      
            font-weight: 300;
            margin-bottom: 20px;
            font-size: 2rem;
         
      }

      p  {
        background: -webkit-linear-gradient($fontColor, $gradiant);
        background-clip: text;
        -webkit-text-fill-color: transparent;
        justify-content: center;   
        margin-bottom: 30px;
        font-size: 1.3rem;
     
    }

    .closerP{
      background: -webkit-linear-gradient($fontColor, $gradiant);
      background-clip: text;
      -webkit-text-fill-color: transparent;
      justify-content: center;   
      margin: 0;
      font-size: 1.3rem;
  }

    a {
      font-size: 1.3rem;  
      color:$buttonColor;
    
  }
     }


     .six {
      position: absolute;
      margin-top:6900px;
      width: 100vw;
      height: 600px;
      background-attachment: fixed;
      background-size: cover;
      background-repeat:  no-repeat;
      background-position: center;
      background-color: $gradiant;
      display:grid;
      grid-template-columns: subgrid;
      grid-column: 1;
      align-items: center;
      align-content: center;
  }
  
  .footer{
    position: absolute;
    top:7650px;
    width: 100%;
    height: 500px;
    display:grid;
    grid-template-columns: subgrid;
    grid-column: 1;
  }
////}

@media only screen and ( min-width: 768px) and (max-width: 991px) {

  .section1{
    margin-top:2000px;
  }
  .six{
    margin-top:8500px;
  }
  .footer{
    top:9200px;
    z-index:20;
    background-color: $gradiant;
    background-size: 100%;
    background-position: center;
  }
}

@media only screen and  (min-width: 411px) and (max-width: 767px)  {
  .article1{
    margin-top:400px
  }
  .section1{
    margin-top:2600px;
  }
  .six{
    margin-top:12000px;
  }
  .footer{
    top:12750px;
    background-color: $gradiant;
    background-size: 100%;
    background-position: center;
    height: 800px;
  }
}

@media only screen and  (min-width: 280px) and (max-width: 410px)  {
  .article1{
    margin-top:400px
  }
  .section1{
    margin-top:3300px;
  }

  .six{
    margin-top:16200px;
  }
  .footer{
    top:16950px;
    background-color: $gradiant;
    background-size: 100%;
    background-position: center;
    height: 800px;
  }
}