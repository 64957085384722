//@import './variables.module.scss';
 

$buttonColor:#0d6efd;
$fontColor: rgb(83, 6, 134);;
$gradiant:rgb(2,0,36);
$backColor:rgb(247, 247, 242);
$linkColor:rgb(44, 44, 131);
$logo-font-size :24px;
$footerWidth: 100%;
$cardColor:rgb(116, 10, 187);
///$inputColor:rgb(21,107,163);
$labelColor:rgb(116, 136, 151);
$inputColor:#ddd;
$placeHolder:rgb(167, 164, 164);
$logofontsize:36px;
$headerWidth: 100%;
$formColor:rgb(6, 15, 117);
$black:black;
$white:white;



.inActive{display:none;}


    .acticle{
    margin: 0 auto;
    width:100vw;
    display:grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: auto;
    justify-items: stretch;
    align-items: stretch;
    place-items: center;
    justify-content: center;
    position:relative;
    padding:0px;
    background-color:$backColor;
    float:center;
    align-items: center;
    overflow:  hidden;
    scrollbar-width: none;
     margin-bottom: 0;
        
    }
    
     
    .img{
        width: 100vw;
        height: auto;
        align-content: center;
        position:absolute;
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
        box-sizing: border-box;
        background-size: cover;
        background-attachment: fixed;
        background-repeat:  no-repeat;
        background-position: center;
        background-color: ($gradiant,09);
        margin-bottom: 50px;
        display:grid;
        grid-template-columns: subgrid;
        grid-column: 1;
      h1{
        margin-left: 5%;
        margin-top: 100px;
        background: -webkit-linear-gradient($gradiant, $buttonColor);
        background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 36px;
      
      
      }
      h3{
        margin: 5%;
        margin-top: 25px;
        margin-bottom: 100px;
        background: -webkit-linear-gradient($gradiant, $buttonColor);
        background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 36x;
       
        
      }
      h2{
        margin: 5%;
        margin-top: 25px;
        margin-bottom: 100px;
        background: -webkit-linear-gradient($gradiant, $buttonColor);
        background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 36x;
        
             }
       
    
      }
      
      .article1{
        width: 80%;
        margin-right: 10%;
        margin-left: 10%;
        position:absolute;
        margin-top:350px;
        display: inline-block;
        background-position: center;
        display:grid;
        grid-template-columns: subgrid;
        grid-column: 1;
        img{
          display: inline-block;
       
        }
        h3{
            background: -webkit-linear-gradient($cardColor, $gradiant);
            background-clip: text;
            -webkit-text-fill-color: transparent;
            font-weight: 200;
            margin-bottom: 50px;
            font-size: 2.2rem;
       
          }
        p  {
            background: -webkit-linear-gradient($fontColor, $gradiant);
            background-clip: text;
            -webkit-text-fill-color: transparent;
            justify-content: center;   
            margin-bottom: 50px;
            font-size: 1.3rem;
            display: inline-block;
           
        }
         a {
          
          justify-content: center;
          margin-top:0px;
          margin-bottom: 0px;
          font-size: 1.2rem;
          color:$gradiant;
          &:hover{color:$buttonColor}
      }
     }
     .section1{
      width: 80%;
      margin-right: 10%;
      margin-left: 10%;
      position:absolute;
      margin-top:1200px;
      background-repeat:  no-repeat;
      background-position: center;
      display:grid;
      grid-template-columns: subgrid;
      grid-column: 1;
      img{
            margin-bottom: 10px;
            font-size: 2rem; 
      }
      h3{
            background: -webkit-linear-gradient($cardColor, $buttonColor);
            background-clip: text;
            -webkit-text-fill-color: transparent;      
            font-weight: 300;
            margin-bottom: 20px;
            font-size: 2rem;
         
      }

      p  {
        background: -webkit-linear-gradient($fontColor, $gradiant);
        background-clip: text;
        -webkit-text-fill-color: transparent;
        justify-content: center;   
        margin-bottom: 30px;
        font-size: 1.3rem;
     
    }
    a {
      font-size: 1.3rem;  
      color:$buttonColor;
    
  }
     }


     .six {
      position: absolute;
      margin-top:6500px;
      width: 100vw;
      height: 600px;
      background-attachment: fixed;
      background-size: cover;
      background-repeat:  no-repeat;
      background-position: center;
      background-color: $gradiant;
      display:grid;
      grid-template-columns: subgrid;
      grid-column: 1;
      align-items: center;
      align-content: center;
  }
  
  .footer{
    position: absolute;
    top:5250px;
    width: 100%;
    height: 500px;
    display:grid;
    grid-template-columns: subgrid;
    grid-column: 1;
  }
////}

@media only screen and ( min-width: 768px) and (max-width: 991px) {

  .six{
    margin-top:8200px;
  }
  .footer{
    top:5500px;
    z-index:20;
    background-color: $gradiant;
    background-size: 100%;
    background-position: center;
  }
}

@media only screen and  (min-width: 411px) and (max-width: 767px)  {
  .article1{
    margin-top:400px
  }
  .section1{
    margin-top:1700px;
  }
  .six{
    margin-top:9000px;
  }
  .footer{
    top:7250px;
    background-color: $gradiant;
    background-size: 100%;
    background-position: center;
    height: 800px;
  }
}

@media only screen and  (min-width: 280px) and (max-width: 410px)  {
  .article1{
    margin-top:400px
  }
  .section1{
    margin-top:1800px;
  }

  .six{
    margin-top:14000px;
  }
  .footer{
    top:10000px;
    background-color: $gradiant;
    background-size: 100%;
    background-position: center;
    height: 800px;
  }
}