
@import './variables.module.scss';




#submit:hover{opacity: 0.5;}
.modal #line1:hover {
    color: red;
    cursor: pointer;
  }
 .modal #line2:hover {
    color: red;
    cursor: pointer;
  }
  #signUp:hover{
    color: $buttonColor;
    cursor: pointer;
  }

  #psw a:hover{
    color:$buttonColor;
    cursor: pointer;
  }

  .inactive{display:none;}

  .errorBorder{ border: 1px solid rgba(214, 9, 9, 0.8); }
  .Border{ border: 1px solid rgba(9, 228, 93, 0.8); }
 
 .h1{
   color:darkblue;
   font-size: 2rem;
   font-weight: 400;
   transform: rotate(-45deg);
   float:left;
   margin-top: 50px;
   position:absolute;

  /* Legacy vendor prefixes that you probably don't need... */

  /* Safari */
  -webkit-transform: rotate(-45deg);

  /* Firefox */
  -moz-transform: rotate(-45deg);

  /* IE */
  -ms-transform: rotate(-45deg);

  /* Opera */
  -o-transform: rotate(-45deg);
 }
  @media only screen and (min-width: 992px) {
.modal {
   // display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 2; /* Sit on top */
    top: 152px;
    width: 100vw; /* Full width */
    height:  100vw; /* Full height */
    overflow: hidden; /* Enable scroll if needed */
    // background-size: cover;
    // background-repeat:  no-repeat;
    // background-position: center;
    // background-attachment: fixed;
 ///   align-content: center;
    font-family: 'Work Sans', sans-serif;
    .container {
      width: 100%; 
      height: 100%;
      background-color: $formColor; /* Fallback color */
      background-color: rgba(6, 15, 117, 0.8); 
      background-size: cover;
      position: absolute;
      #svg{
        height:25px;
        width:25px;
      }
      #line1{
        stroke:$backColor;
        stroke-width:3;
      }
      #line2{
        stroke:$backColor;
        stroke-width:3;
      }
      /* The Close Button */
      .closeBtn {
        /* Position it in the top right corner outside of the modal */
        position: absolute;
        right: 40px;
        top: 20px;
        font-size: 30px;
        font-weight: bold;
      }

    }
  }
  
   #title{
    top:30px;
    left:83px; 
    font-size: 1rem;
    font-family: sans-serif;
    color:$white;
    z-index: 1;
    position:absolute;
   }
     
  #inemail{
    height: 30px;
    width: 45%;
    max-width: 99% ;/// 400px;  
    top:60px;
    left:80px; 
    font-size: 0.9rem;
    z-index: 1;
    position:absolute;
    box-sizing: border-box;
    &:focus-within{color:lightcoral}
    &:focus{color:black}
   }
  #uError{
    height: 20px;
    width: 5px;
    color:red;
    top:60px;
    left:70px;
    position:absolute;
  }
  #inpassword{
    height: 30px;
    width: 45%;
    max-width: 99% ;/// 400px;  
    top:100px;
    left:80px;
    position:absolute;
    font-size: 1rem;
    font-size: 0.9rem;
    z-index: 1;
    box-sizing: border-box;
    outline: none;
    padding-right:20px;
     background-size: 15px;
     background: no-repeat right;
     background-color: $backColor;
  }

  #pError{
    height: 20px;
    width: 5px;
    color:red;
    top:100px;
    left:70px;
    position:absolute;
  }

 

   #recaptcha{
    height:80px;
    width: 40%;
    max-width: 50% ;/// 400px;  
    top:150px;
    left:80px;
    position:absolute;
   }

   #cError{
    height: 20px;
    width: 5px;
    color:red;
    top:150px;
    left:70px;
    position:absolute;
  }

   #insubmit{
    height: 40px;
    width: 45%;
    max-width: 99% ;/// 400px;  
    top:250px;
    left:80px;
    position:absolute;
    color:$backColor;
    background-color: $linkColor;
    border-radius: 5px;
    &:hover{color:red;}
   // border:2 2 solid $inputColor;
  }
    #psw{
    height: 30px;
    width: 400px;
    top:300px;
    left:80px;
    position:absolute;
    a{
        color:$backColor;
        &:hover{color:red}
    }
  }
  
  #label{
    height: 30px;
    width: 280px;
    top:340px;
    left:110px;
    position:absolute;
    color:$backColor;
  }

  #inremember{
    height: 20px;
    width: 20px;
    top:340px;
    left:80px;
    position:absolute;
    
  }

  #signLabel{
    height: 20px;
    top:300px;
    left:40%;
    position:absolute;
    color:$backColor;
  }


  #signUp{
    height: 20px;
    width: 100px;
    top:300px;
    left:55%;
    z-index: 1;
    position:absolute;
    color:$backColor;
    &:hover{color:red}
  }
 

  .google
    {
      width:100px;
      height:41px;
      top: 150px;
      left:60%;
      position:absolute;
      cursor: pointer;
      float:right;
      display: block;
      border:none;
      border-radius: 40px;
      &:hover{opacity:.9;color: brown;}
     }

      .linkedin
    {
      width:100px;
      height:41px;
      top: 195px;
      left:60%;
      position:absolute;
      cursor: pointer;
      float:right;
      display: block;
      border:none;
      align-content: center;
      background-color:$buttonColor;
        &:hover{opacity:.9;color: brown;} 
        .linkedimg
        {
          margin-top:4px;
          width:30px;
          height:31px;
          top: 195px;
          margin-left:5px;
          float:left;
          z-index: 1;
         
        }  
        .linkedp{
          margin-top:8px;
          color:$backColor;
        } 
     }
    
     .twitter
     {
       width:25px;
       height:25px;
       top: 200px;
       left:65%;
       position:absolute;
       cursor: pointer;
       float:right;
       display: block;
       border:none;
       background-color: white;
       border-radius: 50%;
      }

    .facebook
   {
    width:25px;
    height:25px;
     top: 200px;
     left:75%;
     position:absolute;
     cursor: pointer;
     float:right;
     display: block;
     border:none;
     
    }
  /* Add Zoom Animation */
  .animate {
    -webkit-animation: animatezoom 0.6s;
    animation: animatezoom 0.6s
  }
  
  @-webkit-keyframes animatezoom {
    from {-webkit-transform: scale(0)}
    to {-webkit-transform: scale(1)}
  }
  
  @keyframes animatezoom {
    from {transform: scale(0)}
    to {transform: scale(1)}
  }

}
@media only screen and ( min-width: 768px) and (max-width: 991px) {
  .modal {
    // display: none; /* Hidden by default */
     position: fixed; /* Stay in place */
     z-index: 2; /* Sit on top */
     left: 15%;
     top: 152px;
     width: 100vw; /* Full width */
     height: 100vw; /* Full height */
     overflow: hidden; /* Enable scroll if needed */
     background-size: cover;
     background-repeat:  no-repeat;
     background-position: center;
     background-attachment: fixed;
     align-content: center;
     .container {
       width: 100%; 
       height: 100%;
       background: $formColor; /* Fallback color */
       background: rgba(6, 15, 117, 0.8); 
       position: absolute;
       #svg{
         height:25px;
         width:25px;
       }
       #line1{
         stroke:$backColor;
         stroke-width:3;
       }
       #line2{
         stroke:$backColor;
         stroke-width:3;
       }
       /* The Close Button */
       .closeBtn {
         /* Position it in the top right corner outside of the modal */
         position: absolute;
         right: 40px;
         top: 20px;
         font-size: 30px;
         font-weight: bold;
       }
 
     }
   }
   
    
   
   
    
   #inemail{
     height: 30px;
     width: 55%;
     max-width: 99% ;/// 400px;  
     top:60px;
     left:80px; 
     font-size: 0.9rem;
     z-index: 1;
     position:absolute;
     box-sizing: border-box;
     &:focus-within{color:lightcoral}
     &:focus{color:black}
    }
   #uError{
     height: 20px;
     width: 5px;
     color:red;
     top:60px;
     left:70px;
     position:absolute;
   }
   #inpassword{
     height: 30px;
     width: 55%;
     max-width: 99% ;/// 400px;  
     top:100px;
     left:80px;
     position:absolute;
     font-size: 1rem;
     font-size: 0.9rem;
     z-index: 1;
     box-sizing: border-box;
     outline: none;
     padding-right:20px;
     background-size: 15px;
     background: no-repeat right;
     background-color: $backColor;
   }
 
   #pError{
     height: 20px;
     width: 5px;
     color:red;
     top:100px;
     left:70px;
     position:absolute;
   }
 
  
 
    #recaptcha{
     height:80px;
     width: 40%;
     max-width: 50% ;/// 400px;  
     top:150px;
     left:80px;
     position:absolute;
    }
 
    #cError{
     height: 20px;
     width: 5px;
     color:red;
     top:150px;
     left:70px;
     position:absolute;
   }
 
    #insubmit{
     height: 40px;
     width: 55%;
     max-width: 99% ;/// 400px;  
     top:250px;
     left:80px;
     position:absolute;
     color:$backColor;
     background-color: $linkColor;
     border-radius: 5px;
     &:hover{color:red;}
    // border:2 2 solid $inputColor;
   }
     #psw{
     height: 30px;
     width: 400px;
     top:300px;
     left:80px;
     position:absolute;
     a{
         color:$backColor;
         &:hover{color:red}
     }
   }
   
   #label{
     height: 30px;
     width: 280px;
     top:340px;
     left:110px;
     position:absolute;
     color:$backColor;
   }
 
   #inremember{
     height: 20px;
     width: 20px;
     top:340px;
     left:80px;
     position:absolute;
     
   }
 
   #signLabel{
     height: 20px;
     top:300px;
     left:  40%; ///300px;
     position:absolute;
     color:$backColor;
   }
 
 
   #signUp{
     height: 20px;
     width: 100px;
     top:300px;
     left:70%;
     z-index: 1;
     position:absolute;
     color:$backColor;
     &:hover{color:red}
   }
  
 
   .google
     {
       width:100px;
       height:41px;
       top: 150px;
       left:70%;
       position:absolute;
       cursor: pointer;
       float:right;
       display: block;
       border:none;
       border-radius: 40px;
       &:hover{opacity:.9;color: brown;}
      }
 
       .linkedin
     {
       width:100px;
       height:41px;
       top: 195px;
       left:70%;
       position:absolute;
       cursor: pointer;
       float:right;
       display: block;
       border:none;
       align-content: center;
       background-color:$buttonColor;
         &:hover{opacity:.9;color: brown;} 
         .linkedimg
         {
           margin-top:4px;
           width:30px;
           height:31px;
           top: 195px;
           margin-left:5px;
           float:left;
           z-index: 1;
          
         }  
         .linkedp{
           margin-top:8px;
           color:$backColor;
         } 
      }
     
      .twitter
      {
        width:25px;
        height:25px;
        top: 200px;
        left:65%;
        position:absolute;
        cursor: pointer;
        float:right;
        display: block;
        border:none;
        background-color: white;
        border-radius: 50%;
       }
 
     .facebook
    {
     width:25px;
     height:25px;
      top: 200px;
      left:75%;
      position:absolute;
      cursor: pointer;
      float:right;
      display: block;
      border:none;
      
     }
   /* Add Zoom Animation */
   .animate {
     -webkit-animation: animatezoom 0.6s;
     animation: animatezoom 0.6s
   }
   
   @-webkit-keyframes animatezoom {
     from {-webkit-transform: scale(0)}
     to {-webkit-transform: scale(1)}
   }
   
   @keyframes animatezoom {
     from {transform: scale(0)}
     to {transform: scale(1)}
   }
 
}
@media only screen and  (min-width: 411px) and (max-width: 767px)  {
  .modal {
    // display: none; /* Hidden by default */
     position: fixed; /* Stay in place */
     z-index: 2; /* Sit on top */
    // left: 2%;
     top: 160px;
     width: 100vw; /* Full width */
     height: 100vw; /* Full height */
     overflow: hidden; /* Enable scroll if needed */
     background-size: cover;
     background-repeat:  no-repeat;
     background-position: center;
     background-attachment: fixed;
     align-content: center;
     .container {
       width: 100%; 
       height: 100%;
       background: $formColor; /* Fallback color */
       background: rgba(6, 15, 117, 0.8); 
       position: absolute;
       #svg{
         height:25px;
         width:25px;
       }
       #line1{
         stroke:$backColor;
         stroke-width:3;
       }
       #line2{
         stroke:$backColor;
         stroke-width:3;
       }
       /* The Close Button */
       .closeBtn {
         /* Position it in the top right corner outside of the modal */
         position: absolute;
         right: 40px;
         top: 20px;
         font-size: 30px;
         font-weight: bold;
       }
 
     }
   }
   
    
   
   
    
   #inemail{
     height: 30px;
     width: 70%;
     max-width: 99% ;/// 400px;  
     top:20px;
     left:20px; 
     font-size: 0.9rem;
     z-index: 1;
     position:absolute;
     box-sizing: border-box;
     &:focus-within{color:lightcoral}
     &:focus{color:black}
    }
   #uError{
     height: 20px;
     width: 5px;
     color:red;
     top:20px;
     left:10px;
     position:absolute;
   }
   #inpassword{
     height: 30px;
     width: 70%;
     max-width: 99% ;/// 400px;  
     top:60px;
     left:20px;
     position:absolute;
     font-size: 1rem;
     font-size: 0.9rem;
     z-index: 1;
     box-sizing: border-box;
     outline: none;
     padding-right:20px;
     background-size: 15px;
     background: no-repeat right;
     background-color: $backColor;
   }
 
   #pError{
     height: 20px;
     width: 5px;
     color:red;
     top:60px;
     left:10px;
     position:absolute;
   }
 
  
 
    #recaptcha{
     height:80px;
     width: 40%;
     max-width: 50% ;/// 400px;  
     top:150px;
     left:20px;
     position:absolute;
    }
 
    #cError{
     height: 20px;
     width: 5px;
     color:red;
     top:150px;
     left:20px;
     position:absolute;
   }
 
    #insubmit{
     height: 40px;
     width: 70%;
     max-width: 99% ;/// 400px;  
     top:100px;
     left:20px;
     position:absolute;
     color:$backColor;
     background-color: $linkColor;
     border-radius: 5px;
     &:hover{color:red;}
    // border:2 2 solid $inputColor;
   }
     #psw{
     height: 30px;
     width: 400px;
     top:330px;
     left:20px;
     position:absolute;
     a{
         color:$backColor;
         &:hover{color:red}
     }
   }
   
   #label{
     height: 30px;
     width: 280px;
     top:370px;
     left:60px;
     position:absolute;
     color:$backColor;
   }
 
   #inremember{
     height: 20px;
     width: 20px;
     top:370px;
     left:20px;
     position:absolute;
     
   }
 
   #signLabel{
     height: 20px;
     top:300px;
     left:  20px; ///300px;
     position:absolute;
     color:$backColor;
   }
 
 
   #signUp{
     height: 20px;
     width: 100px;
     top:300px;
     left:50%;
     z-index: 1;
     position:absolute;
     color:$backColor;
     &:hover{color:red}
   }
  
 
   .google
     {
       width:100px;
       height:41px;
       top: 250px;
       left:20px;
       position:absolute;
       cursor: pointer;
       float:right;
       display: block;
       border:none;
       border-radius: 40px;
       &:hover{opacity:.9;color: brown;}
      }
 
       .linkedin
     {
       width:100px;
       height:41px;
       top: 250px;
       left:38%;
       position:absolute;
       cursor: pointer;
       float:right;
       display: block;
       border:none;
       align-content: center;
       background-color:$buttonColor;
         &:hover{opacity:.9;color: brown;} 
         .linkedimg
         {
           margin-top:4px;
           width:30px;
           height:31px;
           top: 195px;
           margin-left:5px;
           float:left;
           z-index: 1;
          
         }  
         .linkedp{
           margin-top:8px;
           color:$backColor;
         } 
      }
     
      .twitter
      {
        width:25px;
        height:25px;
        top: 200px;
        left:65%;
        position:absolute;
        cursor: pointer;
        float:right;
        display: block;
        border:none;
        background-color: white;
        border-radius: 50%;
       }
 
     .facebook
    {
     width:25px;
     height:25px;
      top: 200px;
      left:75%;
      position:absolute;
      cursor: pointer;
      float:right;
      display: block;
      border:none;
      
     }
   /* Add Zoom Animation */
   .animate {
     -webkit-animation: animatezoom 0.6s;
     animation: animatezoom 0.6s
   }
   
   @-webkit-keyframes animatezoom {
     from {-webkit-transform: scale(0)}
     to {-webkit-transform: scale(1)}
   }
   
   @keyframes animatezoom {
     from {transform: scale(0)}
     to {transform: scale(1)}
   }
 

}
@media only screen and  (min-width: 280px) and (max-width: 410px)  {

  .modal {
    // display: none; /* Hidden by default */
     position: fixed; /* Stay in place */
     z-index: 2; /* Sit on top */
     left: 0;
     top: 180px;
     width: 100vw; /* Full width */
     height: 100vw; /* Full height */
     overflow: hidden; /* Enable scroll if needed */
     background-size: cover;
     background-repeat:  no-repeat;
     background-position: center;
     background-attachment: fixed;
     align-content: center;
     .container {
       width: 100%; 
       height: 100%;
       background: $formColor; /* Fallback color */
       background: rgba(6, 15, 117, 0.8); 
       position: absolute;
       #svg{
         height:25px;
         width:25px;
       }
       #line1{
         stroke:$backColor;
         stroke-width:3;
       }
       #line2{
         stroke:$backColor;
         stroke-width:3;
       }
       /* The Close Button */
       .closeBtn {
         /* Position it in the top right corner outside of the modal */
         position: absolute;
         right: 40px;
         top: 20px;
         font-size: 30px;
         font-weight: bold;
       }
 
     }
   }
   
    
   
   
    
   #inemail{
     height: 30px;
     width: 65%;
     max-width: 99% ;/// 400px;  
     top:40px;
     left:2%; 
     font-size: 0.9rem;
     z-index: 1;
     position:absolute;
     box-sizing: border-box;
     &:focus-within{color:lightcoral}
     &:focus{color:black}
    }
   #uError{
     height: 20px;
     width: 5px;
     color:red;
     top:40px;
     left:1%;
     position:absolute;
   }
   #inpassword{
     height: 30px;
     width: 65%;
     max-width: 99% ;/// 400px;  
     top:80px;
     left:2%;
     position:absolute;
     font-size: 1rem;
     font-size: 0.9rem;
     z-index: 1;
     box-sizing: border-box;
     outline: none;
     background-size: 15px;
     padding-right: 20px;
     background: no-repeat right;
     background-color: $backColor;
   }
 
   #pError{
     height: 20px;
     width: 5px;
     color:red;
     top:80px;
     left:1%;
     position:absolute;
   }
 
  
 
    #recaptcha{
     height:80px;
     width: 40%;
     max-width: 50% ;/// 400px;  
     top:130px;
     left:2%;
     position:absolute;
    }
 
    #cError{
     height: 20px;
     width: 5px;
     color:red;
     top:130px;
     left:1%;
     position:absolute;
   }
 
    #insubmit{
     height: 40px;
     width: 65%;
     max-width: 99% ;/// 400px;  
     top:230px;
     left:2%;
     position:absolute;
     color:$backColor;
     background-color: $linkColor;
     border-radius: 5px;
     &:hover{color:red;}
    // border:2 2 solid $inputColor;
   }
     #psw{
     height: 30px;
     width: 400px;
     top:280px;
     left:2%;
     position:absolute;
     a{
         color:$backColor;
         &:hover{color:red}
     }
   }
   
   #label{
     height: 30px;
     width: 300px;
     top:350px;
     left:10%;
     position:absolute;
     color:$backColor;
   }
 
   #inremember{
     height: 20px;
     width: 20px;
     top:350px;
     left:2%;
     position:absolute;
     
   }
 
   #signLabel{
     height: 20px;
     top:320px;
     left:  2%; ///300px;
     position:absolute;
     color:$backColor;
   }
 
 
   #signUp{
     height: 20px;
     width: 100px;
     top:320px;
     left:50%;
     z-index: 1;
     position:absolute;
     color:$backColor;
     &:hover{color:red}
   }
  
 
   .google
     {
       width:100px;
       height:41px;
       top: 150px;
       left:70%;
       position:absolute;
       cursor: pointer;
       float:right;
       display: block;
       border:none;
       border-radius: 40px;
       &:hover{opacity:.9;color: brown;}
      }
 
       .linkedin
     {
       width:100px;
       height:41px;
       top: 195px;
       left:70%;
       position:absolute;
       cursor: pointer;
       float:right;
       display: block;
       border:none;
       align-content: center;
       background-color:$buttonColor;
         &:hover{opacity:.9;color: brown;} 
         .linkedimg
         {
           margin-top:4px;
           width:30px;
           height:31px;
           top: 195px;
           margin-left:5px;
           float:left;
           z-index: 1;
          
         }  
         .linkedp{
           margin-top:8px;
           color:$backColor;
         } 
      }
     
      .twitter
      {
        width:25px;
        height:25px;
        top: 200px;
        left:65%;
        position:absolute;
        cursor: pointer;
        float:right;
        display: block;
        border:none;
        background-color: white;
        border-radius: 50%;
       }
 
     .facebook
    {
     width:25px;
     height:25px;
      top: 200px;
      left:75%;
      position:absolute;
      cursor: pointer;
      float:right;
      display: block;
      border:none;
      
     }
   /* Add Zoom Animation */
   .animate {
     -webkit-animation: animatezoom 0.6s;
     animation: animatezoom 0.6s
   }
   
   @-webkit-keyframes animatezoom {
     from {-webkit-transform: scale(0)}
     to {-webkit-transform: scale(1)}
   }
   
   @keyframes animatezoom {
     from {transform: scale(0)}
     to {transform: scale(1)}
   }
}
