@import './variables.module.scss';



#submit:hover{opacity: 0.5;}
#closeBtn:hover {
    color: red;
    cursor: pointer;
  }
  
  .inActive{display:none;}
  .errorBorder{ border: 1px solid rgba(214, 9, 9, 0.8); }
  .Border{ border: 1px solid rgba(9, 228, 93, 0.8); }

  .h1{
    color:darkblue;
    font-size: 2rem;
    font-weight: 400;
    transform: rotate(-45deg);
    float:left;
    margin-top: 50px;
    position:absolute;
 
   /* Legacy vendor prefixes that you probably don't need... */
 
   /* Safari */
   -webkit-transform: rotate(-45deg);
 
   /* Firefox */
   -moz-transform: rotate(-45deg);
 
   /* IE */
   -ms-transform: rotate(-45deg);
 
   /* Opera */
   -o-transform: rotate(-45deg);
  }
  
  @media only screen and (min-width: 992px) {
.modal {
    // display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
     z-index: 1; /* Sit on top */
    // left: 15%;
     top: 152px;
     width: 100vw; /* Full width */
     height: 100vw; /* Full height */
     overflow: hidden; /* Enable scroll if needed */
     background-size: cover;
     background-repeat:  no-repeat;
     background-position: center;
     background-attachment: fixed;
     align-content: center;
     .container {
       width: 100%; 
       height: 100%;
       background: $formColor; /* Fallback color */
       background: rgba(6, 15, 117, 0.8); 
       position: absolute;
       #svg{
         height:25px;
         width:25px;
       }
       #line1{
         stroke:$backColor;
         stroke-width:3;
       }
       #line2{
         stroke:$backColor;
         stroke-width:3;
       }
       /* The Close Button */
       .closeBtn {
         /* Position it in the top right corner outside of the modal */
         position: absolute;
         right: 40px;
         top: 20px;
         font-size: 30px;
         font-weight: bold;
       }
 
     }
   }

   #currentPassword{
    height: 30px;
    width:40%;
    top:180px;
    left:10%;
    position:absolute;
    font-size: 0.9rem;
    box-sizing: border-box;
    padding-right:20px;
    background-size: 15px;
    background: no-repeat right;
    background-color: $backColor;
   }
   #newPassword{
    height: 30px;
    width: 40%;
    top:220px;
    left:10%;
    position:absolute;
    font-size: 0.9rem;
    font-family:  sans-serif;
    box-sizing: border-box;
    padding-right:20px;
    background-size: 15px;
    background: no-repeat right;
    background-color: $backColor;
   }

   #coNewPassword{
    height: 30px;
    width: 40%;
    top:260px;
    left:10%;
    position:absolute;
    font-size: 0.9rem;
    font-family:  sans-serif;
    box-sizing: border-box;
    padding-right:20px;
    background-size: 15px;
    background: no-repeat right;
    background-color: $backColor;
  }

.rnc{
    display: flex;
    flex-direction: column;
    width: 60%;
    max-width: 200px;
    background-color: #eee;
    border-radius: 6px;
    padding: 5px;
    box-sizing: border-box;
    top:240px;
    left:60%;
    position:absolute;
  }
  .rnc-row {
    display: flex;
    align-items: stretch;
    margin: 0 0 5px 0;
  }
  
  .rnc-column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 0 0 5px;
  }
  
  .rnc-canvas {
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 4px;
  }
  
  .rnc-button {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    background: #fff;
    color: inherit;
    border: none;
    padding: 0;
    width: 25px;
    height: 20px;
    box-sizing: border-box;
    border-radius: 4px;
  }
  
   svg {
    width: 1em;
    height: 1em;
    fill: currentColor;
  }
  
  .rnc-input {
    border: none;
    padding: 10px 6px;
    font-size: inherit;
    font-family: inherit;
  }
  

  #submit{
    height: 40px;
    width: 40%;
    top:320px;
    left:10%;
    position:absolute;
    color:$backColor;
    background-color: $linkColor;
    border-radius: 5px;
    z-index: 17;
    font-size: 1rem;
    font-family:  sans-serif;
    &:hover{color:red;}
  }
  }
  
@media only screen and ( min-width: 768px) and (max-width: 991px) {
  .modal {
    // display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
     z-index: 1; /* Sit on top */
    // left: 10%;
     top: 152px;
     width:100vw; /* Full width */
     height: 100vw; /* Full height */
     overflow: hidden; /* Enable scroll if needed */
     background-size: cover;
     background-repeat:  no-repeat;
     background-position: center;
     background-attachment: fixed;
     align-content: center;
     .container {
       width: 100%; 
       height: 100%;
       background: $formColor; /* Fallback color */
       background: rgba(6, 15, 117, 0.8); 
       position: absolute;
       #svg{
         height:25px;
         width:25px;
       }
       #line1{
         stroke:$backColor;
         stroke-width:3;
       }
       #line2{
         stroke:$backColor;
         stroke-width:3;
       }
       /* The Close Button */
       .closeBtn {
         /* Position it in the top right corner outside of the modal */
         position: absolute;
         right: 40px;
         top: 20px;
         font-size: 30px;
         font-weight: bold;
       }
 
     }
   }

   #currentPassword{
    height: 30px;
    width:60%;
    top:80px;
    left:10%;
    position:absolute;
    font-size: 0.9rem;
    box-sizing: border-box;
    padding-right:20px;
    background-size: 15px;
    background: no-repeat right;
    background-color: $backColor;
   }
   #newPassword{
    height: 30px;
    width: 60%;
    top:120px;
    left:10%;
    position:absolute;
    font-size: 0.9rem;
    box-sizing: border-box;
    padding-right:20px;
    background-size: 15px;
    background: no-repeat right;
    background-color: $backColor;
   }

   #coNewPassword{
    height: 30px;
    width: 60%;
    top:160px;
    left:10%;
    position:absolute;
    font-size: 0.9rem;
    box-sizing: border-box;
    padding-right:20px;
    background-size: 15px;
    background: no-repeat right;
    background-color: $backColor;
  }

.rnc{
    display: flex;
    flex-direction: column;
    width: 60%;
    max-width: 200px;
    background-color: #eee;
    border-radius: 6px;
    padding: 5px;
    box-sizing: border-box;
    top:140px;
    left:72%;
    position:absolute;
  }
  .rnc-row {
    display: flex;
    align-items: stretch;
    margin: 0 0 5px 0;
  }
  
  .rnc-column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 0 0 5px;
  }
  
  .rnc-canvas {
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 4px;
  }
  
  .rnc-button {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    background: #fff;
    color: inherit;
    border: none;
    padding: 0;
    width: 25px;
    height: 20px;
    box-sizing: border-box;
    border-radius: 4px;
  }
  
   svg {
    width: 1em;
    height: 1em;
    fill: currentColor;
  }
  
  .rnc-input {
    border: none;
    padding: 10px 6px;
    font-size: inherit;
    font-family: inherit;
  }
  

  #submit{
    height: 40px;
    width: 60%;
    top:220px;
    left:10%;
    position:absolute;
    color:$backColor;
    background-color: $linkColor;
    border-radius: 5px;
    z-index: 17;
    &:hover{color:red;}
  }
}
@media only screen and  (min-width: 411px) and (max-width: 767px)  {
  .modal {
    // display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
     z-index: 1; /* Sit on top */
    // left: 5%;
     top: 160px;
     width: 100vw; /* Full width */
     height: 100vw; /* Full height */
     overflow: hidden; /* Enable scroll if needed */
     background-size: cover;
     background-repeat:  no-repeat;
     background-position: center;
     background-attachment: fixed;
     align-content: center;
     .container {
       width: 100%; 
       height: 100%;
       background: $formColor; /* Fallback color */
       background: rgba(6, 15, 117, 0.8); 
       position: absolute;
       #svg{
         height:25px;
         width:25px;
       }
       #line1{
         stroke:$backColor;
         stroke-width:3;
       }
       #line2{
         stroke:$backColor;
         stroke-width:3;
       }
       /* The Close Button */
       .closeBtn {
         /* Position it in the top right corner outside of the modal */
         position: absolute;
         right: 40px;
         top: 20px;
         font-size: 30px;
         font-weight: bold;
       }
 
     }
   }

   #currentPassword{
    height: 30px;
    width:45%;
    top:40px;
    left:8%;
    position:absolute;
    font-size: 0.9rem;
    box-sizing: border-box;
    padding-right:20px;
    background-size: 15px;
    background: no-repeat right;
    background-color: $backColor;
   }
   #newPassword{
    height: 30px;
    width: 45%;
    top:80px;
    left:8%;
    position:absolute;
    font-size: 0.9rem;
    box-sizing: border-box;
    padding-right:20px;
    background-size: 15px;
    background: no-repeat right;
    background-color: $backColor;
   }

   #coNewPassword{
    height: 30px;
    width: 45%;
    top:120px;
    left:8%;
    position:absolute;
    font-size: 0.9rem;
    box-sizing: border-box;
    padding-right:20px;
    background-size: 15px;
    background: no-repeat right;
    background-color: $backColor;
  }

.rnc{
    display: flex;
    flex-direction: column;
    width: 60%;
    max-width: 200px;
    background-color: #eee;
    border-radius: 6px;
    padding: 5px;
    box-sizing: border-box;
    top:160px;
    left:8%;
    position:absolute;
  }
  .rnc-row {
    display: flex;
    align-items: stretch;
    margin: 0 0 5px 0;
  }
  
  .rnc-column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 0 0 5px;
  }
  
  .rnc-canvas {
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 4px;
  }
  
  .rnc-button {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    background: #fff;
    color: inherit;
    border: none;
    padding: 0;
    width: 25px;
    height: 20px;
    box-sizing: border-box;
    border-radius: 4px;
  }
  
   svg {
    width: 1em;
    height: 1em;
    fill: currentColor;
  }
  
  .rnc-input {
    border: none;
    padding: 10px 6px;
    font-size: inherit;
    font-family: inherit;
  }
  

  #submit{
    height: 40px;
    width: 45%;
    top:290px;
    left:8%;
    position:absolute;
    color:$backColor;
    background-color: $linkColor;
    border-radius: 5px;
    z-index: 17;
    &:hover{color:red;}
  }
}
@media only screen and  (min-width: 280px) and (max-width: 410px)  {
  .modal {
    // display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
     z-index: 1; /* Sit on top */
    // left: 1%;
     top: 180px;
     width: 100vw; /* Full width */
     height: 100vw; /* Full height */
     overflow: hidden; /* Enable scroll if needed */
     background-size: cover;
     background-repeat:  no-repeat;
     background-position: center;
     background-attachment: fixed;
     align-content: center;
     .container {
       width: 100%; 
       height: 100%;
       background: $formColor; /* Fallback color */
       background: rgba(6, 15, 117, 0.8); 
       position: absolute;
       #svg{
         height:25px;
         width:25px;
       }
       #line1{
         stroke:$backColor;
         stroke-width:3;
       }
       #line2{
         stroke:$backColor;
         stroke-width:3;
       }
       /* The Close Button */
       .closeBtn {
         /* Position it in the top right corner outside of the modal */
         position: absolute;
         right: 40px;
         top: 20px;
         font-size: 30px;
         font-weight: bold;
       }
 
     }
   }

   #currentPassword{
    height: 30px;
    width:80%;
    top:40px;
    left:8%;
    position:absolute;
    font-size: 0.9rem;
    box-sizing: border-box;
    outline: none;
    padding-right:20px;
    background-size: 15px;
    background: no-repeat right;
    background-color: $backColor;
   }
   #newPassword{
    height: 30px;
    width: 80%;
    top:80px;
    left:8%;
    position:absolute;
    font-size: 0.9rem;
    box-sizing: border-box;
    outline: none;
    padding-right:20px;
    background-size: 15px;
    background: no-repeat right;
    background-color: $backColor;
   }

   #coNewPassword{
    height: 30px;
    width: 80%;
    top:120px;
    left:8%;
    position:absolute;
    font-size: 0.9rem;
    box-sizing: border-box;
    box-sizing: border-box;
    outline: none;
    padding-right:20px;
    background-size: 15px;
    background: no-repeat right;
    background-color: $backColor;
  }

.rnc{
    display: flex;
    flex-direction: column;
    width: 60%;
    max-width: 200px;
    background-color: #eee;
    border-radius: 6px;
    padding: 5px;
    box-sizing: border-box;
    top:160px;
    left:8%;
    position:absolute;
  }
  .rnc-row {
    display: flex;
    align-items: stretch;
    margin: 0 0 5px 0;
  }
  
  .rnc-column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 0 0 5px;
  }
  
  .rnc-canvas {
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 4px;
  }
  
  .rnc-button {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    background: #fff;
    color: inherit;
    border: none;
    padding: 0;
    width: 25px;
    height: 20px;
    box-sizing: border-box;
    border-radius: 4px;
  }
  
   svg {
    width: 1em;
    height: 1em;
    fill: currentColor;
  }
  
  .rnc-input {
    border: none;
    padding: 10px 6px;
    font-size: inherit;
    font-family: inherit;
  }
  

  #submit{
    height: 40px;
    width: 80%;
    top:290px;
    left:8%;
    position:absolute;
    color:$backColor;
    background-color: $linkColor;
    border-radius: 5px;
    z-index: 17;
    &:hover{color:red;}
  }
}