

@import './variables.module.scss';

.logo {
    color:$backColor;
     width: 150px;
     height: 60px;
     padding: 10px;
     position:absolute;
     font-size: $logo-font-size;
     display:inline-block;
     font-family: Helvetica ,Arial ,sans-serif,    ;
     top: 25px;
     left: 9%;
   }
   .description{
    color:$backColor;
    top: 70px;
    left: 10%;
    position:absolute;
  }
   
   .shape1{
    top: 50px;
    width: 2%;
    height: 5px;
    left:25px;
    background-color:rgb(11, 223, 124);
    display: block;
    position:absolute;
    border-radius: 2px;
    margin: 0;
 }
 .shape2{
   top: 55px;
   width: 2%;
   height: 5px;
   left: 40px;
   background-color: $gradiant;
   display: block;
   position:absolute;
   padding: 0;
   border-radius: 5px;
   
 }
 .shape3{
   top: 60px;
   width: 3.5%;
   height: 5px;
   left: 28px;;
   background-color: rgb(11, 235, 130);
   display: block;
   position:absolute;
   padding-left: 2;
   border-radius: 10px;
  
 }
 .shape4{
    top: 65px;
    width: 1%;
    height: 5px;
    left: 30px;;
    background-color: $gradiant;
    display: block;
    position:absolute;
    padding: 0;
    border-radius: 3px;
 }
 .shape5{
   top: 70px;
   width: 2%;
   height: 5px;
   left:33px;
   background-color: rgb(111, 226, 172);
   display: block;
   position:absolute;
   padding-left: 0;
   border-radius: 11px;
 }
 .shape6{
   top: 75px;
   width: 2%;
   height: 5px;
   left:30px;
   background-color: $gradiant;
   display: block;
   position:absolute;
   padding: 0;
   border-radius: 3px;
 }
 .shape7{
   top: 80px;
   width: .6%;
   height: 5px;
   position:absolute;
   background-color:rgb(11, 223, 124);
   display: block;
   left:28px;
   padding: 0;
   border-radius: 3px;
 }

