@import './variables.module.scss';

.inactive{display:none;}

.modal #line1:hover {
    color: red;
    cursor: pointer;
  }
 .modal #line2:hover {
    color: red;
    cursor: pointer;
  }
 
  .inactive{display:none;}

  
 .h1{
   color:darkblue;
   font-size: 2rem;
   font-weight: 400;
   transform: rotate(-45deg);
   float:left;
   margin-top: 50px;
   position:absolute;

  /* Legacy vendor prefixes that you probably don't need... */

  /* Safari */
  -webkit-transform: rotate(-45deg);

  /* Firefox */
  -moz-transform: rotate(-45deg);

  /* IE */
  -ms-transform: rotate(-45deg);

  /* Opera */
  -o-transform: rotate(-45deg);
 }
 // @media only screen and (min-width: 992px) {
.modal {
   // display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    display:grid;
    z-index: 9; /* Sit on top */
    left: 0;
    top: 21%;
    width: 300px; /* Full width */
    height: 200px; /* Full height */
    overflow: hidden; /* Enable scroll if needed */
    background-size: cover;
    background-repeat:  no-repeat;
    background-position: center;
    background-attachment: fixed;
    align-content: center;
    border: .3rem ;
    .container {
      width: 100%; 
      height: 100%;
      background: $formColor; /* Fallback color */
      background: rgba(6, 15, 117, 0.8); 
      position: absolute;
      #svg{
        height:25px;
        width:25px;
      }
      #line1{
        stroke:$backColor;
        stroke-width:3;
      }
      #line2{
        stroke:$backColor;
        stroke-width:3;
      }
      /* The Close Button */
      .closeBtn {
        /* Position it in the top right corner outside of the modal */
        position: absolute;
        right: 40px;
        top: 30px;
        font-size: 30px;
        font-weight: bold;
      }

    

      img{
        position: absolute;
        left:30px;
        top:20px;
  
      }
      #p1{
        position: absolute;
        align-items: center;
        align-content: center;
        color: $white;
        font-size: 1.5rem;
        top: 50px;
        left:30px;
      }
      #p2{
        position: absolute;
        align-items: center;
        align-content: center;
        color: $white;
        font-size: 1.5rem;
        top: 90px;
        left:30px;

      }
    }
   
  }