//@import './variables.module.scss';


$buttonColor:#0d6efd;
$fontColor: rgb(83, 6, 134);;
$gradiant:rgb(2,0,36);
$backColor:rgb(247, 247, 242);
$linkColor:rgb(44, 44, 131);
$logo-font-size :24px;
$footerWidth: 100%;
$cardColor:rgb(116, 10, 187);
///$inputColor:rgb(21,107,163);
$labelColor:rgb(116, 136, 151);
$inputColor:#ddd;
$placeHolder:rgb(167, 164, 164);
$logofontsize:36px;
$headerWidth: 100%;
$formColor:rgb(6, 15, 117);
$black:black;
$white:white;



.inactive{display:none;}
.errorBorder{ border: 1px solid rgba(214, 9, 9, 0.8); }
.Border{ border: 1px solid rgba(9, 228, 93, 0.8); }

.h1{
  color:darkblue;
  font-size: .5rem;
  font-weight: 400;
  float:left;
  margin-top: 10px;
  margin-left:50%;
  position:absolute;

}

@media only screen and (min-width: 992px) {
.container{
    position:fixed;
    left: 8.1vw;
    top: 0;
    padding:0 5px;
    background-color:$black;
    background-size: cover;
    align-items: center;
    align-content: center;
    width: 91.9vw;
    z-index: 30;
    height:100vh;
    font-size: 14px;
}

#avatar{
    top:30px;
    left:80px;
    width: 25vw;
    height:27vh;
    position:absolute;
    border:1px solid $white;
    text-align: center;
    align-items: center;
}

#fileInPut{
    top:260px;
    left:80px;
    position:absolute;

}



#userName{
    height: 30px;
    width: 30%;
    top:350px;
    left:80px;
    position:absolute;
    font-size: 0.9rem;
   
   }
 
   #uError{
     height: 20px;
     width: 5px;
     color:red;
     top:350px;
     left:70px;
     position:absolute;
   }
 
 
   #email{
     height: 30px;
     width: 30%;
     top:390px;
     left:80px;
     position:absolute;
     font-size: 0.9rem;
     color:white;
    }
    #email::-webkit-input-placeholder {color:white;} /* Chrome/Opera/Safari */
    #email::-moz-placeholder { color: white;} /* Firefox 19+ */
    #email:-ms-input-placeholder { color: white;} /* IE 10+ */
    #email:-moz-placeholder {color: white;}  /* Firefox 18- */
    
    #eError{
     height: 20px;
     width: 5px;
     color:red;
     top:390px;
     left:70px;
     position:absolute;
   }
 
    #phone{
     height: 30px;
     width: 30%;
     top:430px;
     left:80px;
     position:absolute;
     font-size: 0.9rem;
     
    }
 
    #phError{
     height: 20px;
     width: 5px;
     color:red;
     top:430px;
     left:70px;
     position:absolute;
   }

   #country{
    height: 30px;
    width: 30%;
    top:470px;
    left:80px;
    position:absolute;
    font-size: 0.9rem;
   
   }
   #cError{
    height: 20px;
    width: 5px;
    color:red;
    top:470px;
    left:70px;
    position:absolute;
  }

   #education{
    height: 30px;
    width: 30%;
    top:510px;
    left:80px;
    position:absolute;
    font-size: 0.9rem;
     
    }
    #edError{
        height: 20px;
        width: 5px;
        color:red;
        top:510px;
        left:70px;
        position:absolute;
      }

    #companyName{
        height: 30px;
        width: 30%;
        top:550px;
        left:80px;
        position:absolute;
        font-size: 0.9rem;
         
        }

   #coError{
     height: 20px;
     width: 5px;
     color:red;
     top:550px;
     left:70px;
     position:absolute;
   }

   #role{
    height: 30px;
    width: 30%;
    top:170px;
    left:43%;
    position:absolute;
    font-size: 1rem;
   }

   #requester{
    height: 20px;
    width: 20px;
    top:210px;
    left:50%;
    position:absolute;
    font-size: 0.9rem;
   }
   #requesterLabel{
    height: 30px;
    width: 180px;
    top:209px;
    left:53%;
    position:absolute;
    font-size: 1rem;
    color:$white;
    text-align: left;
    padding: 2px 0;
   }
   #provider{
    height: 20px;
    width: 20px;
    top:240px;
    left:50%;
    position:absolute;
   }
   #providerLabel{
    height: 30px;
    width: 180px;
    top:240px;
    left:53%;
    position:absolute;
    font-size: 1rem;
    color:$white;
    padding:2px 0;
    text-align: left;
   }
  
   #teams{
    height: 30px;
    width: 15%;
    top:300px;
    left:46%;
    position:absolute;
    font-size: 1rem;
   }

   #design{
    height: 20px;
    width: 20px;
    top:340px;
    left:50%;
    position:absolute;
   }
   #designLabel{
    height: 30px;
    width: 180px;
    top:340px;
    left:53%;
    position:absolute;
    font-size: 1rem;
    color:$white;
    padding:2px 0;
    text-align: left;
   }

   

   #develop{
    height: 20px;
    width: 20px;
    top:380px;
    left:50%;
    position:absolute;
   }
   #developLabel{
    height: 30px;
    width: 180px;
    top:380px;
    left:53%;
    position:absolute;
    font-size: 1rem;
    color:$white;
    padding:2px 0;
    text-align: left;
   }
   #security{
    height: 20px;
    width: 20px;
    top:420px;
    left:50%;
    position:absolute;
   }
   #securityLabel{
    height: 30px;
    width: 180px;
    top:420px;
    left:53%;
    position:absolute;
    font-size: 1rem;
    color:$white;
    padding:2px 0;
    text-align: left;
   }
   #qa{
    height: 20px;
    width: 20px;
    top:460px;
    left:50%;
    position:absolute;
   }
   #qaLabel{
    height: 30px;
    width: 180px;
    top:460px;
    left:53%;
    position:absolute;
    font-size: 1rem;
    color:$white;
    padding:2px 0;
    text-align: left;
   }

   #submit{
    height: 40px;
    width: 100px;
    top:80px;
    left:80vw;
    position:absolute;
    color:$backColor;
    background-color: $buttonColor;
    border-radius: 5px;
    &:hover{color:red;}
   // border:2 2 solid $inputColor;
  }
}
@media only screen and ( min-width: 768px) and (max-width: 991px) {
  .container{
    position:fixed;
    left: 9vw;
    top: 0;
    padding:0 5px;
    background-color:$black;
    background-size: cover;
    align-items: center;
    align-content: center;
    width: 91vw;
    z-index: 30;
    height:100vh;
    font-size: 14px;
}

#avatar{
    top:30px;
    left:80px;
    width: 25vw;
    height:190px;
    position:absolute;
    border:1px solid $white;
    text-align: center;
    align-items: center;
}

#fileInPut{
  top:260px;
  left:80px;
  position:absolute;

}



#userName{
  height: 30px;
  width: 30%;
  top:350px;
  left:80px;
  position:absolute;
  font-size: 0.9rem;
 
 }

 #uError{
   height: 20px;
   width: 5px;
   color:red;
   top:350px;
   left:70px;
   position:absolute;
 }


 #email{
   height: 30px;
   width: 30%;
   top:390px;
   left:80px;
   position:absolute;
   font-size: 0.9rem;
   color:white;
  
  }

  #email::-webkit-input-placeholder {color:white;} /* Chrome/Opera/Safari */
  #email::-moz-placeholder { color: white;} /* Firefox 19+ */
  #email:-ms-input-placeholder { color: white;} /* IE 10+ */
  #email:-moz-placeholder {color: white;}  /* Firefox 18- */
    
  #eError{
   height: 20px;
   width: 5px;
   color:red;
   top:390px;
   left:70px;
   position:absolute;
 }

  #phone{
   height: 30px;
   width: 30%;
   top:430px;
   left:80px;
   position:absolute;
   font-size: 0.9rem;
   
  }

  #phError{
   height: 20px;
   width: 5px;
   color:red;
   top:430px;
   left:70px;
   position:absolute;
 }

 #country{
  height: 30px;
  width: 30%;
  top:470px;
  left:80px;
  position:absolute;
  font-size: 0.9rem;
 
 }
 #cError{
  height: 20px;
  width: 5px;
  color:red;
  top:470px;
  left:70px;
  position:absolute;
}

 #education{
  height: 30px;
  width: 30%;
  top:510px;
  left:80px;
  position:absolute;
  font-size: 0.9rem;
   
  }
  #edError{
      height: 20px;
      width: 5px;
      color:red;
      top:510px;
      left:70px;
      position:absolute;
    }

  #companyName{
      height: 30px;
      width: 30%;
      top:550px;
      left:80px;
      position:absolute;
      font-size: 0.9rem;
       
      }

 #coError{
   height: 20px;
   width: 5px;
   color:red;
   top:550px;
   left:70px;
   position:absolute;
 }

 #role{
  height: 30px;
  width: 30%;
  top:170px;
  left:45%;
  position:absolute;
  font-size: 1rem;
 }

 #requester{
  height: 20px;
  width: 20px;
  top:210px;
  left:50%;
  position:absolute;
  font-size: 0.9rem;
 }
 #requesterLabel{
  height: 30px;
  width: 180px;
  top:210px;
  left:54%;
  position:absolute;
  font-size: 1rem;
  color:$white;
  text-align: left;
  padding: 2px 0;
 }
 #provider{
  height: 20px;
  width: 20px;
  top:240px;
  left:50%;
  position:absolute;
 }
 #providerLabel{
  height: 30px;
  width: 180px;
  top:240px;
  left:54%;
  position:absolute;
  font-size: 1rem;
  color:$white;
  padding:2px 0;
  text-align: left;
 }

 #teams{
  height: 30px;
  width: 15%;
  top:300px;
  left:46%;
  position:absolute;
  font-size: 1rem;
 }

 #design{
  height: 20px;
  width: 20px;
  top:340px;
  left:50%;
  position:absolute;
 }
 #designLabel{
  height: 30px;
  width: 180px;
  top:340px;
  left:54%;
  position:absolute;
  font-size: 1rem;
  color:$white;
  padding:2px 0;
  text-align: left;
 }

 

 #develop{
  height: 20px;
  width: 20px;
  top:380px;
  left:50%;
  position:absolute;
 }
 #developLabel{
  height: 30px;
  width: 180px;
  top:380px;
  left:54%;
  position:absolute;
  font-size: 1rem;
  color:$white;
  padding:2px 0;
  text-align: left;
 }
 #security{
  height: 20px;
  width: 20px;
  top:420px;
  left:50%;
  position:absolute;
 }
 #securityLabel{
  height: 30px;
  width: 180px;
  top:420px;
  left:54%;
  position:absolute;
  font-size: 1rem;
  color:$white;
  padding:2px 0;
  text-align: left;
 }
 #qa{
  height: 20px;
  width: 20px;
  top:460px;
  left:50%;
  position:absolute;
 }
 #qaLabel{
  height: 30px;
  width: 180px;
  top:460px;
  left:54%;
  position:absolute;
  font-size: 1rem;
  color:$white;
  padding:2px 0;
  text-align: left;
 }

 #submit{
  height: 40px;
  width: 100px;
  top:80px;
  left:70vw;
  position:absolute;
  color:$backColor;
  background-color: $buttonColor;
  border-radius: 5px;
  &:hover{color:red;}
 // border:2 2 solid $inputColor;
}
}

@media only screen and  (min-width: 411px) and (max-width: 767px)  {

  .container{
    position:fixed;
    left: 19vw;
    top: 0;
    padding:0 5px;
    background-color:$black;
    background-size: cover;
    align-items: center;
    align-content: center;
    width: 81vw;
    z-index: 30;
    height:100vh;
    font-size: 14px;
}

#avatar{
    top:10px;
    left:30px;
    width: 50vw;
    height:180px;
    position:absolute;
    border:1px solid $white;
    text-align: center;
    align-items: center;
}

#fileInPut{
  top:220px;
  left:30px;
  position:absolute;

}



#userName{
  height: 30px;
  width: 60%;
  top:250px;
  left:30px;
  position:absolute;
  font-size: 0.9rem;
 
 }

 #uError{
   height: 20px;
   width: 5px;
   color:red;
   top:250px;
   left:20px;
   position:absolute;
 }


 #email{
   height: 30px;
   width:60%;
   top:290px;
   left:30px;
   position:absolute;
   font-size: 0.9rem;
   color:white;
  }

  #email::-webkit-input-placeholder {color:white;} /* Chrome/Opera/Safari */
    #email::-moz-placeholder { color: white;} /* Firefox 19+ */
    #email:-ms-input-placeholder { color: white;} /* IE 10+ */
    #email:-moz-placeholder {color: white;}  /* Firefox 18- */
    
  #eError{
   height: 20px;
   width: 5px;
   color:red;
   top:290px;
   left:20px;
   position:absolute;
 }

  #phone{
   height: 30px;
   width: 60%;
   top:330px;
   left:30px;
   position:absolute;
   font-size: 0.9rem;
   
  }

  #phError{
   height: 20px;
   width: 5px;
   color:red;
   top:330px;
   left:20px;
   position:absolute;
 }

 #country{
  height: 30px;
  width: 60%;
  top:370px;
  left:30px;
  position:absolute;
  font-size: 0.9rem;
 
 }
 #cError{
  height: 20px;
  width: 5px;
  color:red;
  top:370px;
  left:20px;
  position:absolute;
}

 #education{
  height: 30px;
  width: 60%;
  top:410px;
  left:30px;
  position:absolute;
  font-size: 0.9rem;
   
  }
  #edError{
      height: 20px;
      width: 5px;
      color:red;
      top:410px;
      left:20px;
      position:absolute;
    }

  #companyName{
      height: 30px;
      width: 60%;
      top:450px;
      left:30px;
      position:absolute;
      font-size: 0.9rem;
       
      }

 #coError{
   height: 20px;
   width: 5px;
   color:red;
   top:450px;
   left:20px;
   position:absolute;
 }

 #role{
  height: 30px;
  width: 60%;
  top:490px;
  left:10px;
  position:absolute;
  font-size: 1rem;
 }

 #requester{
  height: 20px;
  width: 20px;
  top:530px;
  left:20px;
  position:absolute;
  font-size: 0.9rem;
 }
 #requesterLabel{
  height: 30px;
  width: 180px;
  top:530px;
  left:50px;
  position:absolute;
  font-size: 1rem;
  color:$white;
  text-align: left;
  padding: 2px 0;
 }
 #provider{
  height: 20px;
  width: 20px;
  top:570px;
  left:20px;
  position:absolute;
 }
 #providerLabel{
  height: 30px;
  width: 180px;
  top:570px;
  left:50px;
  position:absolute;
  font-size: 1rem;
  color:$white;
  padding:2px 0;
  text-align: left;
 }

 #teams{
  height: 30px;
  width: 15%;
  top:610px;
  left:30px;
  position:absolute;
  font-size: 1rem;
 }

 #design{
  height: 20px;
  width: 20px;
  top:650px;
  left:20px;
  position:absolute;
 }
 #designLabel{
  height: 30px;
  width: 180px;
  top:650px;
  left:50px;
  position:absolute;
  font-size: 1rem;
  color:$white;
  padding:2px 0;
  text-align: left;
 }
 #develop{
  height: 20px;
  width: 20px;
  top:690px;
  left:20px;
  position:absolute;
 }
 #developLabel{
  height: 30px;
  width: 180px;
  top:690px;
  left:50px;
  position:absolute;
  font-size: 1rem;
  color:$white;
  padding:2px 0;
  text-align: left;
 }
 #security{
  height: 20px;
  width: 20px;
  top:650px;
  left:160px;
  position:absolute;
 }
 #securityLabel{
  height: 30px;
  width: 180px;
  top:650px;
  left:190px;
  position:absolute;
  font-size: 1rem;
  color:$white;
  padding:2px 0;
  text-align: left;
 }
 #qa{
  height: 20px;
  width: 20px;
  top:690px;
  left:160px;
  position:absolute;
 }
 #qaLabel{
  height: 30px;
  width: 180px;
  top:690px;
  left:190px;
  position:absolute;
  font-size: 1rem;
  color:$white;
  padding:2px 0;
  text-align: left;
 }

 #submit{
  height: 40px;
  width: 80px;
  top:210px;
  left:50vw;
  position:absolute;
  color:$backColor;
  background-color: $buttonColor;
  border-radius: 5px;
  &:hover{color:red;}
 // border:2 2 solid $inputColor;
}


}

@media only screen and  (min-width: 280px) and (max-width: 410px)  {

  .container{
    position:fixed;
    left: 22vw;
    top: 0;
    padding:0 5px;
    background-color:$black;
    background-size: cover;
    align-items: center;
    align-content: center;
    width: 78vw;
    z-index: 30;
    height:100vh;
    font-size: 14px;
}

#avatar{
    top:10px;
    left:30px;
    width: 50vw;
    height:180px;
    position:absolute;
    border:1px solid $white;
    text-align: center;
    align-items: center;
}

#fileInPut{
  top:220px;
  left:30px;
  position:absolute;

}



#userName{
  height: 30px;
  width: 60%;
  top:250px;
  left:30px;
  position:absolute;
  font-size: 0.9rem;
 
 }

 #uError{
   height: 20px;
   width: 5px;
   color:red;
   top:250px;
   left:20px;
   position:absolute;
 }


 #email{
   height: 30px;
   width:60%;
   top:290px;
   left:30px;
   position:absolute;
   font-size: 0.9rem;
   color:white;
  }

    #email::-webkit-input-placeholder {color:white;} /* Chrome/Opera/Safari */
    #email::-moz-placeholder { color: white;} /* Firefox 19+ */
    #email:-ms-input-placeholder { color: white;} /* IE 10+ */
    #email:-moz-placeholder {color: white;}  /* Firefox 18- */
    
  #eError{
   height: 20px;
   width: 5px;
   color:red;
   top:290px;
   left:20px;
   position:absolute;
 }

  #phone{
   height: 30px;
   width: 60%;
   top:330px;
   left:30px;
   position:absolute;
   font-size: 0.9rem;
   
  }

  #phError{
   height: 20px;
   width: 5px;
   color:red;
   top:330px;
   left:20px;
   position:absolute;
 }

 #country{
  height: 30px;
  width: 60%;
  top:370px;
  left:30px;
  position:absolute;
  font-size: 0.9rem;
 
 }
 #cError{
  height: 20px;
  width: 5px;
  color:red;
  top:370px;
  left:20px;
  position:absolute;
}

 #education{
  height: 30px;
  width: 60%;
  top:410px;
  left:30px;
  position:absolute;
  font-size: 0.9rem;
   
  }
  #edError{
      height: 20px;
      width: 5px;
      color:red;
      top:410px;
      left:20px;
      position:absolute;
    }

  #companyName{
      height: 30px;
      width: 60%;
      top:450px;
      left:30px;
      position:absolute;
      font-size: 0.9rem;
       
      }

 #coError{
   height: 20px;
   width: 5px;
   color:red;
   top:450px;
   left:20px;
   position:absolute;
 }

 #role{
  height: 30px;
  width: 60%;
  top:485px;
  left:10px;
  position:absolute;
  font-size: 1rem;
 }

 #requester{
  height: 20px;
  width: 20px;
  top:510px;
  left:20px;
  position:absolute;
  font-size: 0.9rem;
 }
 #requesterLabel{
  height: 30px;
  width: 180px;
  top:510px;
  left:50px;
  position:absolute;
  font-size: 1rem;
  color:$white;
  text-align: left;
  padding: 2px 0;
 }
 #provider{
  height: 20px;
  width: 20px;
  top:540px;
  left:20px;
  position:absolute;
 }
 #providerLabel{
  height: 30px;
  width: 180px;
  top:540px;
  left:50px;
  position:absolute;
  font-size: 1rem;
  color:$white;
  padding:2px 0;
  text-align: left;
 }

 #teams{
  height: 30px;
  width: 15%;
  top:565px;
  left:30px;
  position:absolute;
  font-size: 1rem;
 }

 #design{
  height: 20px;
  width: 20px;
  top:590px;
  left:20px;
  position:absolute;
 }
 #designLabel{
  height: 30px;
  width: 180px;
  top:590px;
  left:50px;
  position:absolute;
  font-size: 1rem;
  color:$white;
  padding:2px 0;
  text-align: left;
 }
 #develop{
  height: 20px;
  width: 20px;
  top:620px;
  left:20px;
  position:absolute;
 }
 #developLabel{
  height: 30px;
  width: 180px;
  top:620px;
  left:50px;
  position:absolute;
  font-size: 1rem;
  color:$white;
  padding:2px 0;
  text-align: left;
 }
 #security{
  height: 20px;
  width: 20px;
  top:590px;
  left:160px;
  position:absolute;
 }
 #securityLabel{
  height: 30px;
  width: 180px;
  top:590px;
  left:190px;
  position:absolute;
  font-size: 1rem;
  color:$white;
  padding:2px 0;
  text-align: left;
 }
 #qa{
  height: 20px;
  width: 20px;
  top:620px;
  left:160px;
  position:absolute;
 }
 #qaLabel{
  height: 30px;
  width: 180px;
  top:620px;
  left:190px;
  position:absolute;
  font-size: 1rem;
  color:$white;
  padding:2px 0;
  text-align: left;
 }

 #submit{
  height: 40px;
  width: 80px;
  top:210px;
  left:50vw;
  position:absolute;
  color:$backColor;
  background-color: $buttonColor;
  border-radius: 5px;
  &:hover{color:red;}
 // border:2 2 solid $inputColor;
}

}