
@import "./variables.module.scss";


#emailAd:hover{color:$buttonColor;}

.inactive{display:none;}
.errorBorder{ border: 1px solid rgba(214, 9, 9, 0.8); }
.Border{ border: 1px solid rgba(9, 228, 93, 0.8); }

.fontLink {
  font-family:  sans-serif;
}


@media only screen and (min-width: 992px) {

    .container{
        position:fixed;
        left: 8.1vw;
        top: 0;
        padding:0 5px;
        background-color:$black;
        background-size: cover;
        align-items: center;
        align-content: center;
        width: 91.9vw;
        z-index: 30;
        height:100vh;
        font-size: 14px;
        font-family:  sans-serif;
    }

    #email{
        height: 30px;
        width: 48%;
        top:100px;
        left:10%;
        position:absolute;
        font-size: 0.9rem;
        font-family:  sans-serif;
        border-radius: 5px;
       }
       #eError{
        height: 20px;
        width: 5px;
        color:red;
        top:100px;
        left:8%;
        position:absolute;
      }

       #contractName{
        top:140px;
        left:10%;
        width: 48%;
        height: 30px;
        position:absolute;
        font-size: 0.9rem;
        font-family:  sans-serif;
        border-radius: 5px;
        color:white;
    }

    #contractName::-webkit-input-placeholder {color:white;} /* Chrome/Opera/Safari */
    #contractName::-moz-placeholder { color: white;} /* Firefox 19+ */
    #contractName:-ms-input-placeholder { color: white;} /* IE 10+ */
    #contractName:-moz-placeholder {color: white;}  /* Firefox 18- */

    #cError{
        height: 20px;
        width: 5px;
        color:red;
        top:140px;
        left:8%;
        position:absolute;
      }
    #contract{
        top:190px;
        left:10%;
        width: 48%;
        height: 50px;
        position:absolute;
        font-size: 0.9rem;
        font-family:  sans-serif;
        border-radius: 5px;

    }
   
    #submit{
        height: 40px;
        width: 100px;
        top:50px;
        left:50vw;
        position:absolute;
        color:$backColor;
        background-color: $buttonColor;
        border-radius: 5px;
        &:hover{color:red;}
        font-size: 0.9rem;
        font-family:  sans-serif;
    
      }
      #sign{
        height: 20px;
        width: 20px;
        top:240px;
        left:10%;
        position:absolute;
       }
       #signLabel{
        height: 30px;
        width: 180px;
        top:245px;
        left:13%;
        position:absolute;
        font-size: 1rem;
        color:$white;
      //  padding:2px 0;
        text-align: left;
        font-size: 0.9rem;
        font-family:  sans-serif;
       }

       #sendEmail{
        height: 20px;
        width: 20px;
        top:240px;
        left:40%;
        position:absolute;
       }
       #sendEmailLabel{
        height: 30px;
        width: 180px;
        top:245px;
        left:43%;
        position:absolute;
        font-size: 1rem;
        color:$white;
      //  padding:2px 0;
        text-align: left;
        font-size: 0.9rem;
        font-family:  sans-serif;
       }

    .signDate{
        top:290px;
        position:absolute;
        margin-left:10%;
        z-index: 40;
       #signDate{
        height: 30px;
        font-size: 0.9rem;
        font-family:  sans-serif;
        border-radius: 5px;
    }
  }
  
    #sError{
      height: 20px;
      width: 5px;
      color:red;
      top:290px;
      left:8%;
      position:absolute;
    }
   
    .item{
         width: 70%;
         left:10%;
         top:350px;
         background-color:whitesmoke;
         color:black;
         position:absolute;
         z-index: 31;
         
        }

        .header{
            background-color: $buttonColor;
            height:40px;
            font-size: 1rem;
            font-family:  sans-serif;
            color:$white;
            align-items: center;
            align-content: center;
            padding:10px;
            padding-left:20px;
          

         }
         .rowIsSigned{
            margin-Top:'10px';
            padding:10px;
            font-size: 0.9rem;
            font-family:  sans-serif;
            color:$buttonColor
         }
         .rowIsnotSigned{
            margin-Top:'10px';
            padding:10px;
            font-size: 0.9rem;
            font-family:  sans-serif;
            color:red;
         }

}
@media only screen and ( min-width: 768px) and (max-width: 991px) {
    .container{
        position:fixed;
        left: 8vw;
        top: 0;
        padding:0 5px;
        background-color:$black;
        background-size: cover;
        align-items: center;
        align-content: center;
        width: 91.9vw;
        height: 50vw;
        z-index: 30;
        height:100vh;
        font-size: 14px;
        font-family:  sans-serif;
    }

    #email{
        height: 30px;
        width: 60%;
        top:100px;
        left:10%;
        position:absolute;
        font-size: 0.9rem;
        font-family:  sans-serif;
        border-radius: 5px;
       }
       #eError{
        height: 20px;
        width: 5px;
        color:red;
        top:100px;
        left:8%;
        position:absolute;
      }

       #contractName{
        top:140px;
        left:10%;
        width: 60%;
        height: 30px;
        position:absolute;
        font-size: 0.9rem;
        font-family:  sans-serif;
        border-radius: 5px;
        color:white;
    }

    #contractName::-webkit-input-placeholder {color:white;} /* Chrome/Opera/Safari */
    #contractName::-moz-placeholder { color: white;} /* Firefox 19+ */
    #contractName:-ms-input-placeholder { color: white;} /* IE 10+ */
    #contractName:-moz-placeholder {color: white;}  /* Firefox 18- */

    #cError{
        height: 20px;
        width: 5px;
        color:red;
        top:140px;
        left:8%;
        position:absolute;
      }
    #contract{
        top:190px;
        left:10%;
        width: 50%;
        height: 50px;
        position:absolute;
        font-size: 0.9rem;
        font-family:  sans-serif;
        border-radius: 5px;

    }
   
    #submit{
        height: 40px;
        width: 100px;
        top:50px;
        left:50vw;
        position:absolute;
        color:$backColor;
        background-color: $buttonColor;
        border-radius: 5px;
        &:hover{color:red;}
        font-size: 0.9rem;
        font-family:  sans-serif;
    
      }
      #sign{
        height: 20px;
        width: 20px;
        top:240px;
        left:10%;
        position:absolute;
       }
       #signLabel{
        height: 30px;
        width: 180px;
        top:243px;
        left:14%;
        position:absolute;
        font-size: 1rem;
        color:$white;
      //  padding:2px 0;
        text-align: left;
        font-size: 0.9rem;
        font-family:  sans-serif;
       }

       #sendEmail{
        height: 20px;
        width: 20px;
        top:240px;
        left:40%;
        position:absolute;
       }
       #sendEmailLabel{
        height: 30px;
        width: 180px;
        top:245px;
        left:47%;
        position:absolute;
        font-size: 1rem;
        color:$white;
      //  padding:2px 0;
        text-align: left;
        font-size: 0.9rem;
        font-family:  sans-serif;
       }

    .signDate{
      top:290px;
      position:absolute;
      margin-left:10%;
      z-index: 40;
     #signDate{
      height: 30px;
      font-size: 0.9rem;
      font-family:  sans-serif;
      border-radius: 5px;
  }
}
    #sError{
      height: 20px;
      width: 5px;
      color:red;
      top:290px;
      left:8%;
      position:absolute;
    }
  
    .item{
       
         width: 70%;
         left:10%;
         top:350px;
         background-color:whitesmoke;
         color:black;
         position:absolute;
         z-index: 31;
         
        }

        .header{
            background-color: $buttonColor;
            height:40px;
            font-size: 1rem;
            font-family:  sans-serif;
            color:$white;
            align-items: center;
            align-content: center;
            padding:10px;
            padding-left:20px;

         }
         .rowIsSigned{
            margin-Top:'10px';
            padding:10px;
            font-size: 0.9rem;
            font-family:  sans-serif;
            color:$buttonColor
         }
         .rowIsnotSigned{
            margin-Top:'10px';
            padding:10px;
            font-size: 0.9rem;
            font-family:  sans-serif;
            color:red;
         }
    
}
@media only screen and  (min-width: 411px) and (max-width: 767px)  {
    .container{
        position:fixed;
        left: 19.8vw;
        top: 0;
        padding:0 5px;
        background-color:$black;
        background-size: cover;
        align-items: center;
        align-content: center;
        width: 80vw;
        z-index: 30;
        height:100vh;
        font-size: 14px;
        font-family:  sans-serif;
    }

    #email{
        height: 30px;
        width: 70%;
        top:100px;
        left:10%;
        position:absolute;
        font-size: 0.9rem;
        font-family:  sans-serif;
        border-radius: 5px;
       }
       #eError{
        height: 20px;
        width: 5px;
        color:red;
        top:100px;
        left:8%;
        position:absolute;
      }

       #contractName{
        top:140px;
        left:10%;
        width: 70%;
        height: 30px;
        position:absolute;
        font-size: 0.9rem;
        font-family:  sans-serif;
        border-radius: 5px;
        color:white;
    }

    #contractName::-webkit-input-placeholder {color:white;} /* Chrome/Opera/Safari */
    #contractName::-moz-placeholder { color: white;} /* Firefox 19+ */
    #contractName:-ms-input-placeholder { color: white;} /* IE 10+ */
    #contractName:-moz-placeholder {color: white;}  /* Firefox 18- */


    #cError{
        height: 20px;
        width: 5px;
        color:red;
        top:140px;
        left:8%;
        position:absolute;
      }
    #contract{
        top:190px;
        left:10%;
        width: 50%;
        height: 50px;
        position:absolute;
        font-size: 0.9rem;
        font-family:  sans-serif;
        border-radius: 5px;

    }
   
    #submit{
        height: 40px;
        width: 100px;
        top:50px;
        left:50vw;
        position:absolute;
        color:$backColor;
        background-color: $buttonColor;
        border-radius: 5px;
        &:hover{color:red;}
        font-size: 0.9rem;
        font-family:  sans-serif;
    
      }
      #sign{
        height: 20px;
        width: 20px;
        top:240px;
        left:10%;
        position:absolute;
       }
       #signLabel{
        height: 30px;
        width: 180px;
        top:242px;
        left:18.5%;
        position:absolute;
        font-size: 1rem;
        color:$white;
      //  padding:2px 0;
        text-align: left;
        font-size: 0.9rem;
        font-family:  sans-serif;
       }

       #sendEmail{
        height: 20px;
        width: 20px;
        top:270px;
        left:10%;
        position:absolute;
       }
       #sendEmailLabel{
        height: 30px;
        width: 180px;
        top:275px;
        left:18.5%;
        position:absolute;
        font-size: 1rem;
        color:$white;
      //  padding:2px 0;
        text-align: left;
        font-size: 0.9rem;
        font-family:  sans-serif;
       }

    .signDate{
      top:310px;
      position:absolute;
      margin-left:10%;
      z-index: 40;
     #signDate{
      height: 30px;
      font-size: 0.9rem;
      font-family:  sans-serif;
      border-radius: 5px;
  }
}
    #sError{
      height: 20px;
      width: 5px;
      color:red;
      top:310px;
      left:8%;
      position:absolute;
    }

    .item{
     
         width: 70%;
         left:10%;
         top:400px;
         background-color:whitesmoke;
         color:black;
         position:absolute;
         z-index: 31;
         
        }

        .header{
            background-color: $buttonColor;
            height:40px;
            font-size: 1rem;
            font-family:  sans-serif;
            color:$white;
            align-items: center;
            align-content: center;
            padding:10px;
            padding-left:20px;

         }
         .rowIsSigned{
            margin-Top:'10px';
            padding:10px;
            font-size: 0.9rem;
            font-family:  sans-serif;
            color:$buttonColor
         }
         .rowIsnotSigned{
            margin-Top:'10px';
            padding:10px;
            font-size: 0.9rem;
            font-family:  sans-serif;
            color:red;
         }

}

@media only screen and  (min-width: 280px) and (max-width: 410px)  {

    .container{
        position:fixed;
        left: 20vw;
        top: 0;
        padding:0 5px;
        background-color:$black;
        background-size: cover;
        align-items: center;
        align-content: center;
        width: 80vw;
        z-index: 30;
        height:100vh;
        font-size: 14px;
        font-family:  sans-serif;
    }

    #email{
        height: 30px;
        width: 70%;
        top:100px;
        left:10%;
        position:absolute;
        font-size: 0.9rem;
        font-family:  sans-serif;
        border-radius: 5px;
       }
       #eError{
        height: 20px;
        width: 5px;
        color:red;
        top:100px;
        left:8%;
        position:absolute;
      }

       #contractName{
        top:140px;
        left:10%;
        width: 70%;
        height: 30px;
        position:absolute;
        font-size: 0.9rem;
        font-family:  sans-serif;
        border-radius: 5px;
        color:white;
    }
    #contractName::-webkit-input-placeholder {color:white;} /* Chrome/Opera/Safari */
    #contractName::-moz-placeholder { color: white;} /* Firefox 19+ */
    #contractName:-ms-input-placeholder { color: white;} /* IE 10+ */
    #contractName:-moz-placeholder {color: white;}  /* Firefox 18- */

    #cError{
        height: 20px;
        width: 5px;
        color:red;
        top:140px;
        left:8%;
        position:absolute;
      }
    #contract{
        top:190px;
        left:10%;
        width: 50%;
        height: 50px;
        position:absolute;
        font-size: 0.9rem;
        font-family:  sans-serif;
        border-radius: 5px;

    }
   
    #submit{
        height: 40px;
        width: 100px;
        top:50px;
        left:40vw;
        position:absolute;
        color:$backColor;
        background-color: $buttonColor;
        border-radius: 5px;
        &:hover{color:red;}
        font-size: 0.9rem;
        font-family:  sans-serif;
    
      }
      #sign{
        height: 20px;
        width: 20px;
        top:230px;
        left:10%;
        position:absolute;
       }
       #signLabel{
        height: 30px;
        width: 180px;
        top:235px;
        left:23%;
        position:absolute;
        font-size: 1rem;
        color:$white;
      //  padding:2px 0;
        text-align: left;
        font-size: 0.9rem;
        font-family:  sans-serif;
       }

       #sendEmail{
        height: 20px;
        width: 20px;
        top:260px;
        left:10%;
        position:absolute;
       }
       #sendEmailLabel{
        height: 30px;
        width: 180px;
        top:265px;
        left:23%;
        position:absolute;
        font-size: 1rem;
        color:$white;
      //  padding:2px 0;
        text-align: left;
        font-size: 0.9rem;
        font-family:  sans-serif;
       }

     
    .signDate{
      top: 300px;;
      position:absolute;
      margin-left:10%;
      z-index: 40;
     #signDate{
      height: 30px;
      font-size: 0.9rem;
      font-family:  sans-serif;
      border-radius: 5px;
  }
}

    #sError{
      height: 20px;
      width: 5px;
      color:red;
      top:300px;
      left:8%;
      position:absolute;
      font-size: 1rem;
    }

    .item{
     
         width: 70%;
         left:10%;
         top:400px;
         background-color:whitesmoke;
         color:black;
         position:absolute;
         z-index: 31;
         
        }

        .header{
            background-color: $buttonColor;
            height:40px;
            font-size: 1rem;
            font-family:  sans-serif;
            color:$white;
            align-items: center;
            align-content: center;
            padding:10px;
            padding-left:20px;

         }
         .rowIsSigned{
            margin-Top:'10px';
            padding:10px;
            font-size: 0.9rem;
            font-family:  sans-serif;
            color:$buttonColor
         }
         .rowIsnotSigned{
            margin-Top:'10px';
            padding:10px;
            font-size: 0.9rem;
            font-family:  sans-serif;
            color:red;
         }

}
