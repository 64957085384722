
///@import './variables.module.scss';

$buttonColor:#0d6efd;
$fontColor: rgb(83, 6, 134);;
$gradiant:rgb(2,0,36);
$backColor:rgb(247, 247, 242);
$linkColor:rgb(44, 44, 131);
$logo-font-size :24px;
$footerWidth: 100%;
$cardColor:rgb(116, 10, 187);
///$inputColor:rgb(21,107,163);
$labelColor:rgb(116, 136, 151);
$inputColor:#ddd;
$placeHolder:rgb(167, 164, 164);
$logofontsize:36px;
$headerWidth: 100%;
$formColor:rgb(6, 15, 117);
$black:black;
$white:white;



#submit:hover{opacity: 0.5;}
#minBtn:hover {
    color: red;
    cursor: pointer;
  }
  
  .inactive{display:none;}
  .h1{
    color:darkblue;
    font-size: 1rem;
    font-weight: 400;
    transform: rotate(-45deg);
    float:left;
    margin-top: 50px;
    position:absolute;
 
   /* Legacy vendor prefixes that you probably don't need... */
 
   /* Safari */
   -webkit-transform: rotate(-45deg);
 
   /* Firefox */
   -moz-transform: rotate(-45deg);
 
   /* IE */
   -ms-transform: rotate(-45deg);
 
   /* Opera */
   -o-transform: rotate(-45deg);
  }
  
.onLineChat{
  height: 500px;
  width: 450px;
  left:0;
  bottom: 2px;
  position:fixed;
  border-radius: 5px;
  border: 2px solid black;
  background: rgba(254, 254, 254, 0.99); 
  z-index: 16;
  margin:0px;
  padding: 5px;
  display:block;
}

#header{
    height: 50px;
    width: 450px;
    left:0;
    bottom: 500px;
    position:fixed;
    border-radius: 5px;
    border: 2px solid black;
    background: rgba(254, 254, 254, 0.99); 
    text-align: left;
    font-weight: 10;
    padding: 5px;
    margin:0px;
   }

#title{
  height: 30px;
  width: 35px;
  left:10px;
  text-align: center;
}

#tit{
  color:rgb(138, 138, 139);
  font-size: .8rem;
  display:inline-block;
}
#minBtn1{
        top:23px;
        left:134px;
        position:absolute;
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-top: 12px solid rgb(168, 165, 165);
        display: inline-block;
}

#minBtn2{
    height:4px;
    width:20px;
    left:370px;
    background-color: black;
    position:absolute;
    margin: 13px;
    padding:1px;
}
#newMessage
{
    height: 32px;
    width: 320px;
    margin: 5px;
    bottom: 10px;
    position:absolute;
    border-radius: 5px;
    border: 1px 1px solid rgb(112, 107, 107) ;
    z-index: 16;
    padding:2px;
}


#submit
{
    height: 35px;
    width: 90px;
    left:340px;
    margin: 5px;
    bottom: 10px;
    position:absolute;
    border-radius: 5px;
    border: 1px 1px solid rgb(112, 107, 107) ;
    z-index: 16;
    padding:2px;
}