.message_list {
  width: 100%;
  ///max-height: 440px;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  overflow-x: hidden;
  max-height: calc(100vh - 220px);
  overflow-y: initial;
 // overflow-y: auto;

}

.message_container {

  width: 430px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  margin: 3px;
  padding: 0.5rem;
  border-bottom: 1px solid #c7c3c3;
  border-radius: 5px;
  overflow-y: auto;
}
.message_container>* {
  flex: 1 1 auto;
}

.user, .date {
  font-size: 0.625rem;
  color: #888888;
  flex-direction: column;
 /// padding: 0.1rem;
}

.user {
  min-width: 70px;
}

.msg {
 flex-grow: 1;
 font-size: 1rem;
 align-content: left;
  max-width: 400px;
  overflow-x: hidden;
  overflow-y: auto;
  flex-flow: row wrap;
  align-content: space-between;
  justify-content: space-between;
 /// text-overflow: ellipsis;
  width: 100%;
  line-break: loose;
  p{
    word-wrap: break-word;
  }
  ///-webkit-line-clamp: 4; /* number of lines to show */
 //// line-clamp: 4; 
 /// -webkit-box-orient: vertical;

}

.endMessage{
top: 0;
}
// #container{
//     // margin: 30px;
//     // border-radius: 3px;
//     // display:flex;
//     max-width: 500px;
//      width: 100%;
   
// }
// .message_reverse{
//   flex-direction: row-reverse;
// }
//     .outMessage { 
//       display:flex;
//       align-items: flex-end;
//       justify-content: flex-end;
//       flex-direction: row;
//       position: relative;
//         background-color: rgba(255, 255, 255, 0.8);
//         border: 1px solid rgba(0, 0, 0, 0.1);
//         border-radius: 10px;
//         padding: 10px;
//         font-size: 1rem;
//         background: #ebebeb;
//         border-radius: .2em;
//         margin-left: 25px;
//         &:after {
//           content: '';
//           position :absolute ;
//           left :  0 ;
//           top :  50% ;
//           width :  0 ;
//           height :  0 ;
//           border :  20px solid transparent ;
//           border-right-color :  #ebebeb ;
//           border-left :  0 ;
//           border-bottom :  0 ;
//           margin-top :  -10px ;
//           margin-left :  -20px ;
//         }

    
//       }
//     .date{
//       color:#aaa;
//       font-size: .7rem;
//       flex-wrap: wrap;
//       display: flex;
//       align-items: center;
//       flex-direction: row;
//     }
  
   

//     #user_icon{
//       height: 40px;
//       width: 40px;
//       border-radius: 50%;
//       margin-left: .2rem;
//       display: flex;
//       flex-direction: row;
//       align-items: flex-start;
//     }
//       .name{
//         color:#aaa;
//         font-size: .6rem;  
//         display: flex;
//         align-items: center;
//         flex-direction: row;
//         row-gap: .1rem;
//         column-gap: .1rem;
//         align-items: flex-start;
//         flex-wrap: wrap;
//       }
    
    
//     .inMessage {
//         display: flex;
//         flex-direction: row;
//         align-items: flex-end;
//         justify-content: flex-end;
//         position: relative;
//         background-color: rgba(255, 255, 255, 0.8);
//         border: 1px solid rgba(0, 0, 0, 0.1);
//         border-radius: 10px;
//         padding: 10px;
//         font-size: 1rem;  
//         background: #ebebeb;
//         border-radius: .2em;
//         margin-right: 25px;
//         &:after {
//           content: '';
//           position: absolute;
//           right: 0;
//           top: 50%;
//           width: 0;
//           height: 0;
//           border: 20px solid transparent;
//           border-left-color: #ebebeb;
//           border-right: 0;
//           border-bottom: 0;
//           margin-top: -10px;
//           margin-right: -20px;
//            }
//          }
        