

///@import './variables.module.scss';

$buttonColor:#0d6efd;
$fontColor: rgb(83, 6, 134);;
$gradiant:rgb(2,0,36);
$backColor:rgb(247, 247, 242);
$linkColor:rgb(44, 44, 131);
$logo-font-size :24px;
$footerWidth: 100%;
$cardColor:rgb(116, 10, 187);
///$inputColor:rgb(21,107,163);
$labelColor:rgb(116, 136, 151);
$inputColor:#ddd;
$placeHolder:rgb(167, 164, 164);
$logofontsize:36px;
$headerWidth: 100%;
$formColor:rgb(6, 15, 117);
$black:black;
$white:white;



@media only screen and (min-width: 992px) {

#emailAd
    {
      top: 100px;
      left:65%;
      position:absolute;
      cursor: pointer;
      float:right;
      display: block;
      border:none;
      color:$backColor;
      font-family:  sans-serif ;
      font-size: 1.2rem;
    }
    
    .email
    {
      top: 104px;
      left:63%;
      position:absolute;
      cursor: pointer;
      float:right;
      display: block;
         
    }
    
  }

  @media only screen and ( min-width: 768px) and (max-width: 991px) {

    #emailAd
    {
      top: 100px;
      left:65%;
      position:absolute;
      cursor: pointer;
      float:right;
      display: block;
      border:none;
      color:$backColor;
      font-family:  sans-serif ;
      font-size: 1.2rem;
    }
    
    .email
    {
      top: 104px;
      left:62%;
      position:absolute;
      cursor: pointer;
      float:right;
      display: block;
         
    }
  }

  @media only screen and  (min-width: 411px) and (max-width: 767px)  {

    #emailAd
    {
      top: 110px;
      left:22%;
      position:absolute;
      cursor: pointer;
      float:right;
      display: block;
      border:none;
      color:$backColor;
      font-family:  sans-serif ;
      font-size: 1.2rem;
    }
    
    .email
    {
      top: 114px;
      left:16%;
      position:absolute;
      cursor: pointer;
      float:right;
      display: block;
         
    }
  }
  @media only screen and  (min-width: 280px) and (max-width: 410px)  {

    #emailAd
    {
      top: 110px;
      left:25%;
      position:absolute;
      cursor: pointer;
      float:right;
      display: block;
      border:none;
      color:$backColor;
      font-family:  sans-serif ;
                font-size: 1.2rem;
    }
    
    .email
    {
      top: 114px;
      left:17%;
      position:absolute;
      cursor: pointer;
      float:right;
      display: block;
         
    }
  }



  .twitter
    {
      top: 100px;
      left:90%;
      width:25px;
      height:25px;
      position:absolute;
      cursor: pointer;
      float:right;
      display: block;
      border:none;
      background-color: white;
      border-radius: 50%;
      
     }
.YouTube
    {
      top: 150px;
      left:90%;
      position:absolute;
      cursor: pointer;
      float:right;
      display: block;
      border:none;
      
     }
    .linkedin
    {
      top: 200px;
      left:90%;
      position:absolute;
      cursor: pointer;
      float:right;
      display: block;
      border:none;
      
     }
     
    .facebook
   {
     top: 250px;
     left:90%;
     position:absolute;
     cursor: pointer;
     float:right;
     display: block;
     border:none;
     
    }
    .instagram
   {
     top: 300px;
     left:90%;
     position:absolute;
     cursor: pointer;
     float:right;
     display: block;
     border:none;
     
    }
    .whatsapp
   {
     top: 350px;
     left:90%;
     position:absolute;
     cursor: pointer;
     float:right;
     display: block;
     border:none;
     
    }