@import './variables.module.scss';




#submit:hover{opacity: 0.5;}
#clear:hover{opacity: 0.5;}
#closeBtn:hover {
    color: red;
    cursor: pointer;
  }
  
  .inActive{display:none;}
  .errorBorder{ border: 1px solid rgba(214, 9, 9, 0.8); }
  .Border{ border: 1px solid rgba(9, 228, 93, 0.8); }

  .h1{
    color:darkblue;
    font-size: 2rem;
    font-weight: 400;
    transform: rotate(-45deg);
    float:left;
    margin-top: 50px;
    position:absolute;
 
   /* Legacy vendor prefixes that you probably don't need... */
 
   /* Safari */
   -webkit-transform: rotate(-45deg);
 
   /* Firefox */
   -moz-transform: rotate(-45deg);
 
   /* IE */
   -ms-transform: rotate(-45deg);
 
   /* Opera */
   -o-transform: rotate(-45deg);
  }
  
 @media only screen and (min-width: 992px) {
.modal {
    margin: 0 auto;
    width:100vw;
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    height: 100vw; /* Full height */
    top:152px;
    overflow: hidden; /* Enable scroll if needed */
    background-size: cover;
    background-repeat:  no-repeat;
    background-position: center;
    background-attachment: fixed;
   /// align-content: center;
     .container {
       width: 100%; 
       height: 100%;
       background: $formColor; /* Fallback color */
       background: rgba(6, 15, 117, 0.8); 
       position: absolute;
       #svg{
         height:25px;
         width:25px;
       }
       #line1{
         stroke:$backColor;
         stroke-width:3;
       }
       #line2{
         stroke:$backColor;
         stroke-width:3;
       }
       /* The Close Button */
       .closeBtn {
         /* Position it in the top right corner outside of the modal */
         position: absolute;
         right: 40px;
         top: 20px;
         font-size: 30px;
         font-weight: bold;
       }
 
     }
   }

   #subTitle{
    height: 30px;
    width:30%;
    top:40px;
    left:5%;
    position:absolute;
    font-size: 0.9rem;
    font-family:  sans-serif;
    box-sizing: border-box;
   }
   #cError{
    height: 10px;
    width:5%;
    top:40px;
    left:2%;
    position:absolute;
    color:red;
    font-size: 1.9rem;
    font-family:  sans-serif;
   }
   #signDate{
    height: 30px;
    width: 30%;
    top:60px;
    left:5%;
    position:absolute;
    font-size: 0.9rem;
    font-family: sans-serif;
    box-sizing: border-box;
   }
   #sError{
    height: 10px;
    width: 10px;
    top:60px;
    left:2%;
    position:absolute;
    color:red;
    font-size: 1.9rem;
    font-family: sans-serif;
   }
   #snError{
    height: 10px;
    width: 10px;
    top:60px;
    left:47%;
    position:absolute;
    color:red;
    font-size: 1.9rem;
    font-family: sans-serif;
   }

   #clear{
    height: 40px;
    width: 30%;
    top:150px;
    left:5%;
    position:absolute;
    color:$backColor;
    background-color: $linkColor;
    border-radius: 5px;
    font-size: 1rem;
    font-family:  sans-serif;
    &:hover{color:red;}
  }
   
  #submit{
    height: 40px;
    width: 30%;
    top:190px;
    left:5%;
    position:absolute;
    color:$backColor;
    background-color: $linkColor;
    border-radius: 5px;
    font-size: 1rem;
    font-family:  sans-serif;
    &:hover{color:red;}
  }
  .documentText{
    top:250px;
    left:5%;
    position:absolute;
    color:$backColor;
    font-size: 1rem;
    font-family:  sans-serif;
  }
  .noDocumentText{
    top:250px;
    left:5%;
    position:absolute;
    color:$backColor;
    font-size: 1rem;
    font-family: sans-serif;
  }
  .emailText{
    top:270px;
    left:5%;
    position:absolute;
    color:$backColor;
    font-size: .7rem;
    font-family: sans-serif;
  }
  .discription{
    top:300px;
    left:5%;
    position:absolute;
    color:$backColor;
    font-size: .8rem;
    font-family: sans-serif;
  }
 
  .document{
    top:330px;
    left:5%;
    list-style-type: none;
    position:absolute;
    color:$backColor;
    font-size: 1rem;
    font-family:  sans-serif;
    &:hover{ color:red};
  }
  .fileName{
    left:5%;
    color:$backColor;
    &:hover{ color:red};
  }

  .linkFileName{
    left:5%;
    color:$backColor;
    text-decoration: none;
    font-size: .9rem;
    font-family:  sans-serif;
    cursor: pointer;
    &:hover{ color:red};
  }
  .canvas{
    left:53%;
    top:335px;
    color:$backColor;
    position:absolute;

  }
  
 }
  
@media only screen and ( min-width: 768px) and (max-width: 991px) {
  .modal {
    margin: 0 auto;
    width:100vw;
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    height: 100vw; /* Full height */
    top:152px;
    overflow: hidden; /* Enable scroll if needed */
    background-size: cover;
    background-repeat:  no-repeat;
    background-position: center;
    background-attachment: fixed;
    align-content: center;
     .container {
       width: 100%; 
       height: 100%;
       background: $formColor; /* Fallback color */
       background: rgba(6, 15, 117, 0.8); 
       position: absolute;
       #svg{
         height:25px;
         width:25px;
       }
       #line1{
         stroke:$backColor;
         stroke-width:3;
       }
       #line2{
         stroke:$backColor;
         stroke-width:3;
       }
       /* The Close Button */
       .closeBtn {
         /* Position it in the top right corner outside of the modal */
         position: absolute;
         right: 40px;
         top: 20px;
         font-size: 30px;
         font-weight: bold;
       }
 
     }
   }

   #subTitle{
    height: 30px;
    width:30%;
    top:40px;
    left:5%;
    position:absolute;
    font-size: 0.9rem;
    font-family:  sans-serif;
    box-sizing: border-box;
   }
   #cError{
    height: 10px;
    width:5%;
    top:40px;
    left:2%;
    position:absolute;
    color:red;
    font-size: 1.9rem;
    font-family:  sans-serif;
   }
   #signDate{
    height: 30px;
    width: 30%;
    top:60px;
    left:5%;
    position:absolute;
    font-size: 0.9rem;
    font-family: sans-serif;
    box-sizing: border-box;
   }
   #sError{
    height: 10px;
    width: 10px;
    top:60px;
    left:2%;
    position:absolute;
    color:red;
    font-size: 1.9rem;
    font-family: sans-serif;
   }
   #snError{
    height: 10px;
    width: 10px;
    top:60px;
    left:47%;
    position:absolute;
    color:red;
    font-size: 1.9rem;
    font-family: sans-serif;
   }

   #clear{
    height: 40px;
    width: 30%;
    top:130px;
    left:5%;
    position:absolute;
    color:$backColor;
    background-color: $linkColor;
    border-radius: 5px;
    font-size: 1rem;
    font-family:  sans-serif;
    &:hover{color:red;}
  }
   
  #submit{
    height: 40px;
    width: 30%;
    top:170px;
    left:5%;
    position:absolute;
    color:$backColor;
    background-color: $linkColor;
    border-radius: 5px;
    font-size: 1rem;
    font-family:  sans-serif;
    &:hover{color:red;}
  }
  .documentText{
    top:250px;
    left:5%;
    position:absolute;
    color:$backColor;
    font-size: 1rem;
    font-family:  sans-serif;
  }
  .noDocumentText{
    top:250px;
    left:5%;
    position:absolute;
    color:$backColor;
    font-size: 1rem;
    font-family: sans-serif;
  }
  .emailText{
    top:270px;
    left:5%;
    position:absolute;
    color:$backColor;
    font-size: .7rem;
    font-family: sans-serif;
  }
  .discription{
    top:300px;
    left:5%;
    position:absolute;
    color:$backColor;
    font-size: .8rem;
    font-family: sans-serif;
  }
 
  .document{
    top:330px;
    left:5%;
    list-style-type: none;
    position:absolute;
    color:$backColor;
    font-size: 1rem;
    font-family:  sans-serif;
    &:hover{ color:red};
  }
  .fileName{
    left:5%;
    color:$backColor;
    &:hover{ color:red};
  }

  .linkFileName{
    left:5%;
    color:$backColor;
    text-decoration: none;
    font-size: .9rem;
    font-family:  sans-serif;
    cursor: pointer;
    &:hover{ color:red};
  }
  .canvas{
    left:53%;
    top:335px;
    color:$backColor;
    position:absolute;

  }
}
@media only screen and  (min-width: 411px) and (max-width: 767px)  {
  .modal {
    margin: 0 auto;
    width:100vw;
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    height: 100vw; /* Full height */
    top:152px;
    overflow: hidden; /* Enable scroll if needed */
    background-size: cover;
    background-repeat:  no-repeat;
    background-position: center;
    background-attachment: fixed;
    align-content: center;
     .container {
       width: 100%; 
       height: 100%;
       background: $formColor; /* Fallback color */
       background: rgba(6, 15, 117, 0.8); 
       position: absolute;
       #svg{
         height:25px;
         width:25px;
       }
       #line1{
         stroke:$backColor;
         stroke-width:3;
       }
       #line2{
         stroke:$backColor;
         stroke-width:3;
       }
       /* The Close Button */
       .closeBtn {
         /* Position it in the top right corner outside of the modal */
         position: absolute;
         right: 40px;
         top: 20px;
         font-size: 30px;
         font-weight: bold;
       }
 
     }
   }

   #subTitle{
    height: 30px;
    width:30%;
    top:40px;
    left:5%;
    position:absolute;
    font-size: 0.9rem;
    font-family:  sans-serif;
    box-sizing: border-box;
   }
   #cError{
    height: 10px;
    width:5%;
    top:40px;
    left:2%;
    position:absolute;
    color:red;
    font-size: 1.9rem;
    font-family:  sans-serif;
   }
   #signDate{
    height: 30px;
    width: 30%;
    top:60px;
    left:5%;
    position:absolute;
    font-size: 0.9rem;
    font-family: sans-serif;
    box-sizing: border-box;
   }
   #sError{
    height: 10px;
    width: 10px;
    top:60px;
    left:2%;
    position:absolute;
    color:red;
    font-size: 1.9rem;
    font-family: sans-serif;
   }
   #snError{
    height: 10px;
    width: 10px;
    top:60px;
    left:47%;
    position:absolute;
    color:red;
    font-size: 1.9rem;
    font-family: sans-serif;
   }

   #clear{
    height: 40px;
    width: 30%;
    top:130px;
    left:5%;
    position:absolute;
    color:$backColor;
    background-color: $linkColor;
    border-radius: 5px;
    font-size: 1rem;
    font-family:  sans-serif;
    &:hover{color:red;}
  }
   
  #submit{
    height: 40px;
    width: 30%;
    top:170px;
    left:5%;
    position:absolute;
    color:$backColor;
    background-color: $linkColor;
    border-radius: 5px;
    font-size: 1rem;
    font-family:  sans-serif;
    &:hover{color:red;}
  }
  .documentText{
    top:250px;
    left:5%;
    position:absolute;
    color:$backColor;
    font-size: 1rem;
    font-family:  sans-serif;
  }
  .noDocumentText{
    top:250px;
    left:5%;
    position:absolute;
    color:$backColor;
    font-size: 1rem;
    font-family: sans-serif;
  }
  .emailText{
    top:270px;
    left:5%;
    position:absolute;
    color:$backColor;
    font-size: .7rem;
    font-family: sans-serif;
  }
  .discription{
    top:300px;
    left:5%;
    position:absolute;
    color:$backColor;
    font-size: .8rem;
    font-family: sans-serif;
  }
 
  .document{
    top:330px;
    left:5%;
    list-style-type: none;
    position:absolute;
    color:$backColor;
    font-size: 1rem;
    font-family:  sans-serif;
    &:hover{ color:red};
  }
  .fileName{
    left:5%;
    color:$backColor;
    &:hover{ color:red};
  }

  .linkFileName{
    left:5%;
    color:$backColor;
    text-decoration: none;
    font-size: .9rem;
    font-family:  sans-serif;
    cursor: pointer;
    &:hover{ color:red};
  }
  .canvas{
    left:53%;
    top:335px;
    color:$backColor;
    position:absolute;

  }
}
@media only screen and  (min-width: 280px) and (max-width: 410px)  {
  .modal {
    margin: 0 auto;
    width:100vw;
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    height: 100vw; /* Full height */
    top:152px;
    overflow: hidden; /* Enable scroll if needed */
    background-size: cover;
    background-repeat:  no-repeat;
    background-position: center;
    background-attachment: fixed;
    align-content: center;
     .container {
       width: 100%; 
       height: 100%;
       background: $formColor; /* Fallback color */
       background: rgba(6, 15, 117, 0.8); 
       position: absolute;
       #svg{
         height:25px;
         width:25px;
       }
       #line1{
         stroke:$backColor;
         stroke-width:3;
       }
       #line2{
         stroke:$backColor;
         stroke-width:3;
       }
       /* The Close Button */
       .closeBtn {
         /* Position it in the top right corner outside of the modal */
         position: absolute;
         right: 40px;
         top: 20px;
         font-size: 30px;
         font-weight: bold;
       }
 
     }
   }

   #subTitle{
    height: 30px;
    width:30%;
    top:40px;
    left:5%;
    position:absolute;
    font-size: 0.9rem;
    font-family:  sans-serif;
    box-sizing: border-box;
   }
   #cError{
    height: 10px;
    width:5%;
    top:40px;
    left:2%;
    position:absolute;
    color:red;
    font-size: 1.9rem;
    font-family:  sans-serif;
   }
   #signDate{
    height: 30px;
    width: 30%;
    top:60px;
    left:2%;
    position:absolute;
    font-size: 0.9rem;
    font-family: sans-serif;
    box-sizing: border-box;
   }
   #sError{
    height: 10px;
    width: 10px;
    top:60px;
    left:2%;
    position:absolute;
    color:red;
    font-size: 1.9rem;
    font-family: sans-serif;
   }
   #snError{
    height: 10px;
    width: 10px;
    top:60px;
    left:47%;
    position:absolute;
    color:red;
    font-size: 1.9rem;
    font-family: sans-serif;
   }

   #clear{
    height: 40px;
    width: 30%;
    top:130px;
    left:5%;
    position:absolute;
    color:$backColor;
    background-color: $linkColor;
    border-radius: 5px;
    font-size: 1rem;
    font-family:  sans-serif;
    &:hover{color:red;}
  }
   
  #submit{
    height: 40px;
    width: 30%;
    top:170px;
    left:5%;
    position:absolute;
    color:$backColor;
    background-color: $linkColor;
    border-radius: 5px;
    font-size: 1rem;
    font-family:  sans-serif;
    &:hover{color:red;}
  }
  .documentText{
    top:250px;
    left:5%;
    position:absolute;
    color:$backColor;
    font-size: 1rem;
    font-family:  sans-serif;
  }
  .noDocumentText{
    top:250px;
    left:5%;
    position:absolute;
    color:$backColor;
    font-size: 1rem;
    font-family: sans-serif;
  }
  .emailText{
    top:270px;
    left:5%;
    position:absolute;
    color:$backColor;
    font-size: .7rem;
    font-family: sans-serif;
  }
  .discription{
    top:300px;
    left:5%;
    position:absolute;
    color:$backColor;
    font-size: .8rem;
    font-family: sans-serif;
  }
 
  .document{
    top:330px;
    left:5%;
    list-style-type: none;
    position:absolute;
    color:$backColor;
    font-size: 1rem;
    font-family:  sans-serif;
    &:hover{  color:red};
  }
  #fileName{
    left:5%;
    color:$backColor;
    cursor: pointer;
    &:hover{  color:red};
  }
  #fileName:hover{color: red;}

  #linkFileName{
    left:5%;
    color:$backColor;
    text-decoration: none;
    font-size: .9rem;
    font-family:  sans-serif;
    cursor: pointer;
    &:focus{ opacity:50%; color:red};
  }
  #linkFileName:hover{color: red;}
  .canvas{
    left:53%;
    top:335px;
    color:$backColor;
    position:absolute;

  }
}