$backColor:rgb(247, 247, 242);
$buttonColor:#0d6efd;


@media only screen and  (min-width: 501px){
     section.slideshow{
        width: 100%;
     //  left:56%;
       
     }

     section.slideshow section.current-slide{
        width: 100vw;
        height: 55vh;
        margin: auto 10px;
      
    }
}

@media only screen and  (max-width: 500px){

    section.slideshow{
     //   width: 100vw;
        left:0;
    }

    section.slideshow section.current-slide{
    //    width: 100%;
        height: 55vh;
        margin: 10px;
      
    }

}
section.slideshow div.slide-holder{
  //  display: flex;
   // justify-content: space-evenly;
  //  align-items: center;
  //  flex-direction: row;
}

// section.slideshow section.previous-slide,
// section.slideshow section.next-slide{
//     width: 200px;
//     height: 150px;
// }
section.slideshow section.slide div.slide-thumbnail{
    width: 100%;
    height: 100%;
  //  background: $backColor;
    background-position: center;
    background-repeat: no-repeat;
  //  border-radius: 6px;
 //   box-shadow: 5px 5px 5px 5px #ada9b16b;
 //   -webkit-transition: all 1s;
//	-o-transition: all 1s;
 //	transition: all 1s;
}
section.slideshow div.slideshow-controller{
    margin-top: 10px;
    display: flex;
    //justify-content: space-between;
    align-items: center;
    height: 100px;
    width: 90%;
    position: relative;
  
}
section.slideshow div.slideshow-controller span{
    margin: 10px;
    width: 15px;
    height: 15px;
   // background: $backColor;
    //color: #f5f5f5;
    //cursor: pointer;
    text-align: center;
   // box-shadow: 3px 3px 5px #551d8283;
   // user-select: none;
    border-radius: 50%;
    
    //border:1px solid $buttonColor;
    position: absolute;
    z-index: 1;
	-webkit-transition: all 0.6s;
	-o-transition: all 0.6s;
	transition: all 0.6s;
    cursor: pointer;
     top:0px;
     border:1px solid $buttonColor;
    background-color: #FFFFFF;
}
.previous{
   
    left:49%;
    
  }
 
 .next{
   left:52%;
   
   }
//    .previous:hover{
//     border:1px solid $buttonColor;
//     background-color: #FFFFFF;
//   } 
  
//   .next:hover{
//     background-color: $buttonColor;
    
 // } 
section.slideshow div.slideshow-controller span:hover{
    background-color: $buttonColor;
}
section.slideshow div.slideshow-controller span:active{
    background-color: $buttonColor;
}